<template>
  <main class="page-wrapper">
    
    <Header v-if="headshow" :data="$props" />

    <div class="main-content">
      <slot></slot>
    </div>
    <div v-if="dtshow" class="world-box">
      <div class="world">
        <div class="world-bg">
          <div class="y-title">WORLD</div>
          <div class="z-title">
            <span class="sp1"></span>
            <span class="sp2"></span>
            <span class="sp3">销售区域</span>
          </div>

          <div class="pic">
            <img src="@/assets/images/index/dt2.png" />
          </div>
        </div>
      </div>
    </div>

    <footer class="rn-footer">
      <div class="footer-top">
        <div class="nav-box">
          <div class="nav">
            <div
              class="nav-list"
              v-for="(item, index) in footerNav"
              :key="item.id + item.pid"
            >
              
              <div class="title">
                <a :href="item.link">{{ item.title }}</a>
              </div>
              <div class="list">
                <p
                  v-for="(item2, index2) in item.children"
                  :key="item2.id + item2.pid"
                >
                  <router-link :to="item2.link">{{ item2.title }}</router-link>
                </p>
                
              </div>
            </div>
            <!-- <div class="nav-list">
              <div class="title">子公司</div>
              <div class="list">
                <p>湖北金禄</p>
                <p>香港凯美诺</p>
              </div>
            </div>
            <div class="nav-list">
              <div class="title">关于我们</div>
              <div class="list">
                <p>公司简介</p>
                <p>公司简介</p>
                <p>公司简介</p>
              </div>
            </div>
            <div class="nav-list">
              <div class="title">关于我们</div>
              <div class="list">
                <p>公司简介</p>
              </div>
            </div>
            <div class="nav-list">
              <div class="title">关于我们</div>
              <div class="list">
                <p>公司简介</p>
              </div>
            </div> -->
          </div>
        </div>
        <div class="inf">
          <div class="title">{{ siteconfig.company }}</div>
          <div class="inner">
            <ul class="">
              <li class="p1" v-html="siteconfig.content">

              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="inf">
          <div class="title">{{ siteconfig.company_name }}</div>
          <div class="inner">
            <ul class="">
              <li class="p1">
                <img src="@/assets/images/index/wz.png" />
                <span class="sp1">{{ siteconfig.company_addr }}</span>
              </li>
              <li class="p1">
                <img src="@/assets/images/index/telephone.png" />
                <span class="sp2">{{ siteconfig.company_tel.tel1 }}</span>
                <span class="sp2">{{ siteconfig.company_tel.tel2 }}</span>
              </li>
              <li class="p1">
                <img src="@/assets/images/index/landline.png" />
                <span class="sp2">{{ siteconfig.company_phone }}</span>
              </li>
              <li class="p1">
                <img src="@/assets/images/index/facsimile.png" />
                <span class="sp2">{{ siteconfig.company_fax }}</span>
              </li>
              <li class="p1">
                <img src="@/assets/images/index/mail.png" />
                <span class="sp2">{{ siteconfig.company_mail }}</span>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="footer-bottom">
        <div class="nav-bot">©{{getYear()}} 金禄电子科技股份有限公司 版权所有<a hidefocus="true" href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">{{beian}}</a></div>

        <div class="inf-bot">
          <p class="p3">
            <span> 隐私条款 </span>
            <span class="spp"></span>
            <span> 网站地图 </span>
            <span class="spp"></span>
            <span> 加入我们 </span>
            <span class="spp"></span>
            <span> 联系我们 </span>
          </p>
        </div>
      </div>
    </footer>

    <!-- <footer class="rn-footer footer-style-default footer-style-1">
      <div class="footer-top">
        <div class="container">
          <div
            class="row"
            style="display: flex; justify-content: space-between"
          >
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <div class="footzdy">
                <div
                  class="rn-footer-widget"
                  v-for="(item, index) in footerNav"
                  :key="item.id + item.pid"
                >
                
                  <div class="widget-menu-top">
                    <h4 class="title">
                      <a :href="item.link">{{ item.title }}</a>
                    </h4>
                    <div class="inner">
                      <ul class="footer-link link-hover">
                        <li
                          v-for="(item2, index2) in item.children"
                          :key="item2.id + item2.pid"
                        >
                          <router-link :to="item2.link">{{
                            item2.title
                          }}</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="footright col-lg-5 col-md-6 col-sm-12 col-12">
              <div class="rn-footer-widget">
                <h4 class="title">{{ siteconfig.company_name }}</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li class="p1 p2">
                      <img src="@/assets/images/index/wz.png" />
                      <span class="sp1">{{ siteconfig.company_addr }}</span>
                    </li>
                    <li class="p1">
                      <img src="@/assets/images/index/wz.png" />
                      <span class="sp2">{{ siteconfig.company_tel.tel1 }}</span>
                      <span class="sp2">{{ siteconfig.company_tel.tel2 }}</span>
                    </li>
                    <li class="p1">
                      <img src="@/assets/images/index/wz.png" />
                      <span class="sp2">{{ siteconfig.company_phone }}</span>
                    </li>
                    <li class="p1">
                      <img src="@/assets/images/index/wz.png" />
                      <span class="sp2">{{ siteconfig.company_fax }}</span>
                    </li>
                    <li class="p1">
                      <img src="@/assets/images/index/wz.png" />
                      <span class="sp2">{{ siteconfig.company_mail }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row"
            style="display: flex; justify-content: space-between"
          >
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <p class="p2">©2018 金禄电子科技股份有限公司 版权所有</p>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-6 col-12">
              <p class="p3">
                <span> 隐私条款 </span>
                <span>*</span>
                <span> 网站地图 </span>
                <span>*</span>
                <span> 加入我们 </span>
                <span>*</span>
                <span> 联系我们 </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer> -->
  </main>
</template>

<script>
import Header from "./header/Header";
import { getIndex } from "@/api/api";

export default {
  name: "Layout",
  components: { Header },
  props: {
    logo: {
      type: String,
    },
    showTopHeader: {
      type: Boolean,
      default: true,
    },
    topbarStyle: {
      type: Number,
      default: 1,
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true,
    },
    headerTransparency: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
    },
    navAlignment: {
      type: String,
      default: "right",
    },
    buyButtonClass: {
      type: String,
      default: "btn-default btn-small round",
    },
    footerStyle: {
      type: Number,
      default: 3,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showCopyright: {
      type: Boolean,
      default: true,
    },
    showNewsletter: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      footerNav: [],
      siteconfig: {},
      headerNav: [],
      dtshow: false,
      dtshowName: '',
      headshow: false,
      beian: ''
    };
  },
  created() {
    console.log("进入123");
    this.init();
    //window.addEventListener('mousemove',this.gomou)
  },
  methods: {
    getYear(){
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      return currentYear
    },
    gomou(e) {
      console.log(e);
      console.log(e.pageX);
      console.log(e.pageY);
      var x = e.pageX;
      var y = e.pageY;
      var mtop = document.querySelector(".m-top");
      var mbottom = document.querySelector(".m-bottom");
      var mleft = document.querySelector(".m-left");
      var mright = document.querySelector(".m-right");
      mtop.style.left = x - 5 + "px";
      mtop.style.height = y - 5 + "px";
      mbottom.style.left = x - 5 + "px";
      mbottom.style.height = y - 5 + "px";
      mleft.style.top = y - 5 + "px";
      mright.style.top = y - 5 + "px";
      mleft.style.width = x - 5 + "px";
      mright.style.width = x - 5 + "px";
    },
    async init() {
      let this_ = this;

     
      if (this_.$route.name === "Demo") {
        this_.dtshow = true;
      }
      this.dtshowName = this.$route.name;
      
      // header_nav
      // site_config
      // footer_nav
      let res = {};
      if (
        sessionStorage.getItem("header_nav") &&
        sessionStorage.getItem("header_nav") &&
        sessionStorage.getItem("site_config")
      ) {
        this_.footerNav = JSON.parse(sessionStorage.getItem("header_nav"));
        this_.headerNav = JSON.parse(sessionStorage.getItem("header_nav"));
        this_.siteconfig = JSON.parse(sessionStorage.getItem("site_config"));
        this_.beian = sessionStorage.getItem("beian")
      } else {
        res = await getIndex();
        console.log(res)
        sessionStorage.setItem("footer_nav", JSON.stringify(res.header_nav));
        sessionStorage.setItem("header_nav", JSON.stringify(res.header_nav));
        sessionStorage.setItem("site_config", JSON.stringify(res.site_config));
        sessionStorage.setItem("logo", res.logo)
        sessionStorage.setItem("beian", res.beian)
        this_.beian = res.beian
        this_.footerNav = res.header_nav;
        this_.siteconfig = res.site_config;
      }
      this_.headshow =true
      //需要保存头部导航和底部导航
    },
  },
};
</script>
<style scoped>
.m-top,
.m-bottom,
.m-left,
.m-right {
}
.m-top,
.m-bottom {
  width: 1px;
}
.m-top {
  top: 0;
}
.m-bottom {
  bottom: 0;
}
.m-left,
.m-right {
}
.m-left {
  left: 0;
}
.m-right {
  right: 0;
}
.y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  line-height: 60px;
  letter-spacing:  4px;
}
.z-title {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
.z-title .sp1 {
  width: 60px;
  height: 4px;
  border-radius: 0px 2px 2px 0px;
  background: #ffffff;
  opacity: 1;
  display: inline-block;
}
.z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #ffffff;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 45px;
}
.pic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pic img {
  width: 1469px;
  
}
.footer-style-default .footer-top {
  background: #3b3b3b;
}
.footzdy {
  display: flex;
  justify-content: space-between;
}
.footzdy .title {
  font-size: 12px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 33px;
  -webkit-background-clip: text;
}
.footright {
  justify-content: center;
}

.footright .p1 img {
  margin-right: 5px;
  width: 12px;
  height: 15px;
}
.footright .p1 .sp1 {
  font-size: 12px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 24px;
}
.footright .p1 .sp2 {
  font-size: 12px;
  font-family: Bahnschrift-, Bahnschrift;
  font-weight: normal;
  color: #f8f8f8;
  line-height: 32px;
}

.footright .p2 {
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #727272;
  line-height: 19px;
}
.footright .p3 {
  display: flex;
  justify-content: space-between;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #727272;
  line-height: 19px;
}
.main-content {
  background: #ffffff;
  z-index: 20;
  margin-top: -5px;
}

.world-box {
  /* padding: 0 200px; */
  width: 1920px;
  height: 1024px;

  background: #3b3b3b;
}
.world-box .world {
  padding: 0 200px;
  background: #121325;
  border-radius: 0px 0px 100px 0px;
}
.world-box .world .world-bg {
  margin-left: 136px;
  padding: 79px 0;
}
.rn-footer {
  padding: 0 200px;
  padding-top: 99px;
  background: #3b3b3b;
}
.rn-footer p {
  margin: 0;
  padding: 0;
}
ul,
li,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-top .nav-box .nav-list {
}
.footer-top .nav-box .nav-list .title {
  font-size: 18px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 33px;
  margin-bottom: 15px;
}
.footer-top .nav-box .nav-list .title a{
  font-size: 18px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 33px;
}
.footer-top .nav-box .nav-list .list p {
  font-size: 16px;
  font-family: SourceHanSansSC-Regular-GBpc-EUC-H-,
    SourceHanSansSC-Regular-GBpc-EUC-H;
  font-weight: normal;
  color: #969696;
  line-height: 33px;
  margin-bottom: 15px;
}

.footer-top .nav-box .nav-list .list p a{
  font-size: 16px;
  font-family: SourceHanSansSC-Regular-GBpc-EUC-H-,
    SourceHanSansSC-Regular-GBpc-EUC-H;
  font-weight: normal;
  color: #969696;
  line-height: 33px;
}
.footer-top{
  justify-content: space-between;
}
.footer-top .nav-box .nav {
  display: flex;
  justify-content: space-between;
}
.footer-top .nav-box .nav-list {
 
}
.footer-top .nav-box .nav-list:last-child{
  margin-right: 0;
}
.footer-top {
  display: flex;
}
.footer-top .inf {
  
}
.footer-top .inf img {
  width: 15px;

}
.footer-top .inf .title {
  font-size: 24px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 33px;
  margin-bottom: 50px;
}
.footer-top .inf .title a{
  
}
.footer-top .inf .inner {
}
.footer-top .inf .inner ul li {
  margin-bottom: 25px;
}
.footer-top .inf .inner ul li.p1 .sp2 {
  margin-left: 10px;
  font-size: 16px;
  font-family: Bahnschrift-, Bahnschrift;
  font-weight: normal;
  color: #f8f8f8;
  line-height: 32px;
}
.footer-top .inf .inner ul li.p1 .sp1 {
  margin-left: 10px;
  font-size: 13px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 24px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #727272;
  line-height: 19px;
  padding-top: 40px;
  padding-bottom: 48px;
}
.footer-bottom .nav-bot {
  width: 699px;
}
.footer-bottom .nav-bot a {
  margin-left: 20px;
    color: inherit;
    text-decoration: none;
}
.footer-bottom .inf-bot {
  width: 533px;
}
.footer-bottom .inf-bot .p3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-, SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #727272;
}
.footer-bottom .inf-bot .p3 .spp{
  display: flex;
  align-items: center;
  width: 4px;
  height: 4px;
  background: #727272;
  border-radius: 2px;
}
.footer-top .nav-box{
  width: 60%;
}
.footer-top .inf{
  width: 30%;
}
</style>

