<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">PROCESS CAPABILITY</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">制程能力</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2" v-for="(val, key, i) in pageData" :key="i">
        <div class="matop">
          <div class="blue title">
            <span class="sp1">
              {{ val.title }}
            </span>
            <span class="sp2">
              {{ val.desc }}
            </span>
          </div>
        </div>
        <div class="matop" v-for="(item, index) in val.props_array" :key="item.value + item.name">
          <div class="title">
            <span class="sp1">
              {{ item.name }}
            </span>
            <span class="sp2">
              {{ item.value }}
            </span>
          </div>
          <!-- <div v-if="index==0" :class="[index == 0 ? 'blue' : '', 'title']">
            <span class="sp1">
              {{ val.title }}
            </span>
            <span class="sp2">
              {{ val.desc }}
            </span>
          </div>
          <div v-else class="title">
            <span class="sp1">
              {{ item.name }}
            </span>
            <span class="sp2">
              {{ item.value }}
            </span>
          </div> -->
        </div>
      </div>
    </div>

  </Layout>
</template>


<script>
import Layout from "../components/common/Layout";
import { getAbility } from "@/api/api";

export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getAbility();
      this_.pageData = res.data;
      console.log('this_.pageData', this_.pageData)
    },
  },
};
</script>
<style scoped lang="scss">
.main-content {
  background: #ffffff;
}

.matop {
  font-size: 20px;
  line-height: 45px;
  color: #242424;
  border-bottom: 1px solid #fff;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-, SourceHanSansSC-Medium-GBpc-EUC-H;

  .title {
    color: #6c6461;
    display: flex;

    .sp1 {
      padding: 12px 0;
      display: inline-block;
      text-align: center;
      width: 240px;
      background-color: #e0e0e0;
    }

    .sp2 {
      padding: 12px 0;
      display: inline-block;
      text-indent: 80px;
      flex: 1;
    }
  }

  &:nth-child(2n) {
    .sp2 {
      background-color: #d4f0fc;
    }
  }

  .blue {
    color: #0864c6;

    .sp1,
    .sp2 {
      background-color: transparent;
    }
  }
}

.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}

.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}

.demo .title {}

.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing: 4px;
}

.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;

}

.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}

.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}

.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}

.box2 {
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}

.demo {
  background-image: url("~@/assets/images/6-03.png");

  background-repeat: no-repeat;
  background-position: center 240px;

}
.demo {
  text-align: justify;
}
</style>