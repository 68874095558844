<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">EQUIPMENT SITUATION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">设备情况</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="pos" style="white-space: pre-wrap;" v-html="pageData.describe">
          
        </div>
      </div>
      
      <div class="box2">
        <div class="con">
          <div class="con-left">
            <div class="title"> {{ lan == 'en' ? 'Production equipment' : '生产设备' }}</div>
            <div class="left">
              <div v-for="(item, index) in pageData.device" :key="'device'+index">
                <div class="honor-nav">
                  <img
                  :src="backurl + item.image"
                  />
                  <div class="honor-fot">
                    <p class="p1">{{item.title || ''}}</p>
                    <p class="p2" v-if="item.describe">
                      {{ item.describe || '' }}
                    </p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="con-right">
            <div class="title"> {{ lan == 'en' ? 'Testing equipment' : '检测设备' }}</div>
            <div class="right">
              <div v-for="(item, index) in pageData.product_test" :key="'product_test'+index">
                <div class="honor-nav">
                  <img
                  :src="backurl + item.image"
                  />
                  <div class="honor-fot">
                    <p class="p1">{{item.title || ''}}</p>
                    <p class="p2" v-if="item.describe">
                      {{ item.describe || '' }}
                    </p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

import { getDevice } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      lan: sessionStorage.getItem('lang')
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getDevice();
      console.log(res)
      this_.pageData = res.data;
    },
  },
};
</script>
<style scoped>
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.bg-pos {
  position: relative;
}
.pos {
  width: 1203px;
  padding: 45px 45px 45px 136px;
  background: #efefef;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #333333;
  line-height: 45px;
  display: flex;

  align-items: center;
}
.con .title {
  color: #0864c6;
}
.con {
  display: flex; /* flex布局 */
  justify-content: flex-start; /* 左对齐 */
  flex-wrap: wrap; /* 换行 */
}
.con .con-left {
  /* 每个元素右间距设置为20px */
  /* 计算每个元素的宽度：20px是前三个元素的间距（margin-right）和，除以4即为每个元素的宽度 */
  
}

.con .con-right {
  /* 每个元素右间距设置为20px */
  /* 计算每个元素的宽度：20px是前三个元素的间距（margin-right）和，除以4即为每个元素的宽度 */
  
}
.con .con-left .honor-nav {
  
}
.con .con-right .honor-nav {
  
}
.con .con-left div,
.con .con-right div {
}
.con p {
  margin: 0;
  padding: 0;
}
.con .con-left-con {
  /* display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; */
}

.con .con-right-con {
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
}
/* .honor-nav {
  margin-bottom: 10px;
}
.honor-nav img {
  width: 299px;
  height: 195px;
}
.honor-nav div {
  background: #efefef;
  width: 299px;
  padding: 20px;
}
.honor-nav div .p1 {
  color: #0864c6;
}
.honor-nav div .p2 {
  font-size: 11px;
  color: #0864c6;
} */

.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.con {
  display: flex;
}
.con .con-left .title{
  font-size: 25px;
  color:#0864C6;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-;
}
.con .con-left {
  flex-wrap: wrap;
  width: 888px;
}
.con-left .left{
  flex-wrap: wrap;
  display: flex;
 
}
.con-right .right{
  flex-wrap: wrap;
  display: flex;
}
.con .con-right {
  flex-wrap: wrap;
  width: 430px;
}
.con .con-right .title{
  font-size: 25px;
  color:#0864C6;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-;
}
.honor-nav{
  width: 430px;
  margin-bottom: 14px;
  margin-right: 14px;
}
.honor-nav img{
  width: 430px;
  height: 223px;
}
.honor-fot{
  background: #EFEFEF;
}
.honor-nav .p1{
  padding-left: 27px;
  height:80px;
  line-height: 40px;
  color: #0864C6;
  font-size: 20px;
}
.honor-nav .p2{
  padding-left: 27px;
  height:30px;
  line-height: 15px;
  color:#0864C6;
  font-size: 16px;
}
.demo{
  background-image: url("~@/assets/images/6-03.png");

background-repeat: no-repeat;
background-position: center 240px;
  
}
.demo {
  text-align: justify;
}
</style>