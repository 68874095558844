<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">DEVELOPMENT PATH</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              
            </div>
            <div class="z-title">
              <span class="sp3"> 发展历程 </span>
            </div>
            
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="history">
            <div class="his-left">
              <ul class="his-list">
                <li
                  v-for="(item, index) in pageData"
                  :key="item.id"
                  v-show="(index + 1) % 2 != 0"
                >
                  <div class="d2">
                    <p class="p1">{{ item.title }}</p>
                    <p><img :src="backurl + item.thumb_image" /></p>
                  </div>
                  <div class="d1">
                    <span>{{ item.year }}</span>
                    <p><img src="@/assets/images/jinluUnderstand/d1.png" /></p>
                  </div>
                </li>
                <!-- <li>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/1.png" /></p>
                  </div>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d1.png" /></p>
                  </div>
                </li>
                <li>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/3.png" /></p>
                  </div>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d1.png" /></p>
                  </div>
                </li>
                <li>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/5.png" /></p>
                  </div>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d1.png" /></p>
                  </div>
                </li>
                <li>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/1.png" /></p>
                  </div>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d1.png" /></p>
                  </div>
                </li>
                <li>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/1.png" /></p>
                  </div>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d1.png" /></p>
                  </div>
                </li> -->
              </ul>
            </div>
            <div class="his-bor"></div>
            <div class="his-right">
              <ul class="his-list">
                <li
                  v-for="(item, index) in pageData"
                  :key="item.id"
                  v-show="(index + 1) % 2 == 0"
                >
                  <div class="d1">
                    <span>{{ item.year }}</span>
                    <p><img src="@/assets/images/jinluUnderstand/d2.png" /></p>
                  </div>
                  <div class="d2">
                    <p class="p1">{{ item.title }}</p>
                    <p><img :src="backurl + item.thumb_image" /></p>
                  </div>
                </li>
                <!-- <li>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d2.png" /></p>
                  </div>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/1.png" /></p>
                  </div>
                  
                </li>
                <li>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d2.png" /></p>
                  </div>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/2.png" /></p>
                  </div>
                  
                </li>
                <li>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d2.png" /></p>
                  </div>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/4.png" /></p>
                  </div>
                  
                </li>
                <li>
                  <div class="d1">
                    <span>2006年</span>
                    <p><img src="@/assets/images/jinluUnderstand/d2.png" /></p>
                  </div>
                  <div class="d2">
                    <p class="p1">前身金禄（清远）精密科研投资有限公司成立</p>
                    <p><img src="@/assets/images/jinluUnderstand/6.png" /></p>
                  </div>
                  
                </li> -->
              </ul>
            </div>
          </div>
      </div>
    </div>



  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getDevelopment } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getDevelopment();

      this_.pageData = res.data;
    },
  },
};
</script>
<style scoped>
ul,
li,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
p {
  margin: 0;
  padding: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.y-title {
  height: 44px;
  font-size: 33px;
  font-family: AdobeHeitiStd-Regular-GBpc-EUC-H-,
    AdobeHeitiStd-Regular-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  line-height: 33px;
}
.z-title {
  display: flex;
  align-items: center;
}
.z-title .sp1 {
  width: 40px;
  height: 2px;
  background: #0864c6;
  opacity: 1;
  display: inline-block;
}
.z-title .sp2 {
  margin: 0 5px;
  width: 2px;
  height: 2px;
  background: #0864c6;
  border-radius: 1px 1px 1px 1px;
  display: inline-block;
}
.z-title .sp3 {
  font-size: 17px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 25px;
}
.history {
  display: flex;
  justify-content: center;
  margin: 0 136px;
}
.his-left {
  width: 50%;
}
.his-bor {
  width: 1px;
  background: #0864c6;
  padding-bottom: 150px;
}
.his-right {
  width: 50%;
}
.his-list li {
  display: flex;
  justify-content: space-between;
  margin: 34px 0;
}
.d1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 31px;
  line-height: 37px;
  color: #0864c6;
}
.his-left .d1 p {
  background: #ffffff;
  margin-right: -8px;
  z-index: 200;
}
.his-left .d1 p img{
  height: 16px;
}
.d2 {
  width: 465px;
  
}
.d2 .p1 {
  font-size: 18px;
  font-family: SourceHanSansSC-Light-GBpc-EUC-H-,
    SourceHanSansSC-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  background: #0864c6;
  padding: 20px 32px;
  /* padding: 0 32px;
  height: 97px;
  line-height: 97px; */
}
.d2 p img{
  width: 465px;
  height: 261px;

}
.his-right {
  padding-top: 205px;
}
.his-right .d1 p {
  background: #ffffff;
  margin-left: -9px;
  z-index: 200;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;
  justify-content: flex-end;
}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;
  justify-content: flex-end;
}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.demo .box2{
  border-left: 2px solid #0864c6;
  margin-top: -155px;
  padding-bottom: 68px;
}
.demo{
  background-image: url("~@/assets/images/bg2.png");

  background-repeat: no-repeat;
  background-position: top center;
}
.demo {
  text-align: justify;
}
</style>