<template>
  <div class="header-nav">
    <!-- Start Header Area -->
    <header class="hea">
      <div class="header-box position-relative">
        <div class="header-con">
          <template>
            <div class="logo">
              <Logo />
            </div>
            <div class="head">
              <div class="header-right">
                <nav class="mainmenu-nav d-lg-block">
                  <Nav :getpic="getpic" />
                </nav>

                <!-- Start Header Btn -->
                <div class="header-btn2" @click="langClick2">
                  <!-- <img src="@/assets/images/index/earth.png" /> -->
                  <img class="earth1" src="@/assets/images/earth1.png" />
                  <img class="earth2" src="@/assets/images/earth2.png" />
                </div>
                <div class="header-btn" @click="langClick">
                  <a :class="data.buyButtonClass" > {{lang == 'en' ? 'en' : 'cn'}} </a>
                </div>
                <!-- End Header Btn  -->

                <!-- Start Mobile-Menu-Bar -->
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="pic">
          <div class="test">
            <div class="autoplay" style="z-index: 99;">
              <swiper
                :options="swiperOption"
                ref="videoSwiper"
                v-if="initOrNot"
              >
                <swiper-slide v-for="(item, index) in mediaNews" :key="index">
                  <video
                    :class="{'minHeight': heightType==1}"
                    style="width: 100%; object-fit: fill"
                    v-if="item.type === 'video'"
                    :src="medtype==1 ? backurl + item.url : item.url"
                    
                    muted
                    @ended="endVideo(index)"
                    class="multimedia"
                  ></video>
                  <img v-else :class="{'minHeight': heightType==1}" :src="medtype==1 ? backurl + item.url : item.url" class="multimedia" />
                </swiper-slide>
                <div
                  class="swiper-pagination"
                  v-if="mediaNews.length>1"
                  v-for="(item, index) in mediaNews"
                  :key="index"
                  slot="pagination"
                ></div>
                <!-- <div class="swiper-pagination"></div> -->
                <!-- <div class="swiper-button-prev" slot="navigation"></div> -->
                <!-- 左箭头。如果放置在swiper外面，需要自定义样式。-->
                <!--<div class="swiper-button-next" slot="navigation"></div> -->
                <!--右箭头。如果放置在swiper外面，需要自定义样式。-->

                <div class="swiper-button-prev" slot="button-prev" v-if="mediaNews.length>1"></div>
                <div class="swiper-button-next"  slot="button-next" v-if="mediaNews.length>1"></div>
              </swiper>
 
            </div>
          </div>
          <!-- <img :src="require('@/assets/images/index/'+ dtshowName)" @error="setDefaultImage"/> -->
        </div>
      </div>
    </header>
    <div class="left"></div>
    <div class="right"></div>
    <div class="xin"></div>
    <div class="top"></div>
    <div :class="{'bottom2': heightType==1, 'bottom':heightType==2}"></div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Icon from "../../icon/Icon";
import Nav from "./Nav";
import Logo from "../../elements/logo/Logo";
import { getIndex } from "@/api/api";
export default {
  name: "Header",
  props: {
    data: {
      default: null,
    },
  },
  components: { Logo, Nav, swiper, swiperSlide },
  data() {
    return {
      dtshowName: "",
      mediaLastIndex: 0,
      lang: sessionStorage.getItem('lang') || 'cn',
      swiperOption: {
        speed: 1000,
        loop: false,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        allowTouchMove: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChangeTransitionEnd: () => {
            this.slideChangeTransitionEndHandle()
          },
          slideChangeTransitionStart: () => {
            this.slideChangeTransitionStartHandle()
          },
          //控制第一个slide切换
          init: () => {
            this.initHandle()
          }
        }
      },
      initOrNot: false, //mediaNews数据改变的时候确保swiper会init一次
      backurl: "https://jl.szhknet.cn",
      medtype: 1,
      mediaNews: [],
      mediaNews2: [
        {
          url: 'https://video.dzmnc.com/f742c70005cc71eeb0c64701b5bb0102/95baf0dc99f54508a64e77c5d2778203-e89e18862ef1f727620c37b605a614b2-sd.mp4',
          type: "video"
        },
        {
          url: 'https://video.dzmnc.com/f73be93005cc71ee805e4710b4ba0102/584cd834f4f94745b503fc26b5b08d90-b27e19eb96499b9f944c62abeaf385eb-sd.mp4',
          type: "video"
        },
        {
          url: 'https://video.dzmnc.com/f73fe0d005cc71eea82e7621a7ac0102/4c3b7da1acc846d096149c8c247aa302-3a9da67e70d3d63020620ff75f6da69f-sd.mp4',
          type: "video"
        }
        // 1为视频类2为图片类
        // { url: require('./assets/莫干山1.jpg'), type: 2 },
        // { url: require('./assets/v1.mp4'), type: 1 },
        // { url: require('./assets/v1.mp4'), type: 1 },
        // { url: require('./assets/莫干山1.jpg'), type: 2 }
      ],
      heightType:2,
      heightlist:[
      // '/JinluUnderstandProfile',
      // '/JinluUnderstandHistory',
      // '/JinluUnderstandCulture',
      // '/JinluUnderstandHonor',
      // '/JinluUnderstandContact',
      // '/ProductIntroduce',
      // '/ProductAbility',
      // '/ProductPatent',
      // '/ProductSituation',
      // '/JinluUnderstandProfile',
      '/'
      ]
    };
  },
  methods: {},
  created() {
    //this.dtshowName = require('@/assets/images/index/'+ this.$route.name + '.png')
    this.dtshowName = this.$route.name + ".png";
  },
  mounted() {},
  methods: {
    langClick2() {
      window.open(" http://qyjlpcb.com:9081/ekp");
     
    },
    
    async langClick() {
      let this_ = this;

      if(this.lang == 'cn'){
        this.lang = 'en'
        sessionStorage.setItem('lang', 'en')
      } else {
        sessionStorage.setItem('lang', 'cn')
        this.lang = 'cn'
      }
      let res = {};
      res = await getIndex();
      console.log(res)
      sessionStorage.setItem("footer_nav", JSON.stringify(res.header_nav));
      sessionStorage.setItem("header_nav", JSON.stringify(res.header_nav));
      sessionStorage.setItem("site_config", JSON.stringify(res.site_config));
      sessionStorage.setItem("logo", res.logo)
      this_.footerNav = res.header_nav;
      this_.siteconfig = res.site_config;
      location.reload()
    },
    getpic(data) {
      
      let this_ = this
      this_.medtype = 1
      let headerNav = JSON.parse(sessionStorage.getItem('header_nav'))
      console.log('data', this_.heightlist.indexOf(this_.$route.path ))
      if(this_.heightlist.indexOf(this_.$route.path ) >= 0){
        this_.heightType = 2
      }else{
        this_.heightType = 1
      }
      if(JSON.stringify(data) == "{}"){
        if(this_.$route.path == '/NewDetails'){
          
          return this_.mediaNews = [{
            type: 'image',
            url: '/uploads/20230226/641e35b5ae2256e978828c8595765d64.png'
          }]
        }
        
        this_.mediaNews = headerNav[0].bannerfiles
      }else{
        this_.mediaNews = data.bannerfiles
      }
      
      console.log('this_.mediaNews',this_.mediaNews)
      if(this_.mediaNews.length<=0) {
        this_.medtype = 2
        this_.mediaNews = this_.mediaNews2
        console.log(this_.mediaNews)
      }
      
    },
    setDefaultImage(e) {
      e.target.src = require("@/assets/images/index/Demo.png");
    },
    initHandle() {
      let that = this;
      // console.log(this.mediaNews)
      setTimeout(function () {
        console.log(that.$refs.videoSwiper);
        let swiper = that.$refs.videoSwiper.swiper;
        if (that.mediaNews[0]?.type === 2) {
          that.mediaNewsImgHandle(swiper);
        } else {
          document.getElementsByClassName("multimedia")[0]?.play();
        }
      }, 200);
    },
    mediaNewsImgHandle(swiper) {
      let that = this;
      //刚切换到的activeIndex
      let changePointActiveIndex = swiper.activeIndex;
      if (swiper.activeIndex < this.mediaNews.length - 1) {
        setTimeout(function () {
          //要确认changePointActiveIndex是不是还是目前的activeIndex，是的话计时后执行，不是的话不执行
          if (changePointActiveIndex === swiper.activeIndex) {
            swiper.slideNext();
          }
        }, 10000);
      } else {
        setTimeout(function () {
          if (changePointActiveIndex === swiper.activeIndex) {
            swiper.slideTo(0, 0);
          }
        }, 10000);
      }
    },
    slideChangeTransitionStartHandle() {
      let swiper = this.$refs.videoSwiper.swiper;
      if (this.mediaNews[this.mediaLastIndex].type === 'video') {
        document.getElementsByClassName("multimedia")[
          this.mediaLastIndex
        ].currentTime = 0;
      } else {
      }
    },
    slideChangeTransitionEndHandle() {
      // console.log('end..')
      let that = this;
      let swiper = that.$refs.videoSwiper.swiper;
      if (this.mediaNews[this.mediaLastIndex].type === 'video') {
        document
          .getElementsByClassName("multimedia")
          [this.mediaLastIndex].pause();
      }
      if (this.mediaNews[swiper.activeIndex].type === 'image') {
        this.mediaNewsImgHandle(swiper);
      } else {
        document
          .getElementsByClassName("multimedia")
          [swiper.activeIndex].play();
      }

      this.mediaLastIndex = swiper.activeIndex;
    },
    endVideo(index) {
      let swiper = this.$refs.videoSwiper.swiper;
      if (index === swiper.activeIndex) {
        if (swiper.activeIndex < this.mediaNews.length - 1) {
          swiper.slideNext();
          if (this.mediaNews[swiper.activeIndex].type === 'video') {
            document
              .getElementsByClassName("multimedia")
              [swiper.activeIndex].play();
          } else {
            this.mediaNewsImgHandle(swiper);
          }
        } else {
          swiper.slideTo(0, 0);
          if (this.mediaNews[swiper.activeIndex].type === 'video') {
            document
              .getElementsByClassName("multimedia")
              [swiper.activeIndex].play();
          } else {
            this.mediaNewsImgHandle(swiper);
          }
        }
      }
    },
  },
  watch: {
    mediaNews: {
      handler(newName, oldName) {
        this.initOrNot = false
        this.$nextTick(() => {
          this.initOrNot = true
        })
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.header-btn2{
  cursor:pointer;
}
.header-btn{
  cursor:pointer;
}
.header-nav {
  /* padding-top: 30px; */
}
.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
}
.header-btn2 {
  margin: 0 30px;
}
.header-btn2 .earth1{
  width: 39px;
  display: none;
}
.header-btn2 .earth2{
  width: 39px;
  display: block;
  
}
.header-btn2:hover .earth1{
  display: none;
}
.header-btn2:hover .earth2{
  display: block;
}
.header-right .header-btn a.btn-default,
button.btn-default {
  padding: 0 30px;
  background: #0864c6;
}
.hea {
  background-color: transparent;
}
.pic {
  margin-top: -130px;
}
.pic img {
  width: 1920px;
  /* height: 945px; */
  /* height: 650px; */
  
  height:945px;
}
.pic video {
  width: 1920px;
  
  height:945px;
}
.pic .minHeight{
  height:650px;
}
.hea {
}
.header-box {
  padding: 40px 200px;
  z-index: 300;
}
.header-box:hover{
  background-color: rgb(0,0,0,.2);
}
.header-con {
  display: flex;
  align-items: center;
}
.logo {
  padding: 0 52px;
  width: 279px;
}
.head {
  padding-left: 108px;
  width: 100%;
}
.left {
  position: absolute;
  left: 0;
  top: 138px;
  width: 182px;
  height: 0px;
  opacity: 1;
  border: 1px solid #ffffff;
  z-index: 199;
}
.top {
  position: absolute;
  top: 0;
  left: 200px;
  width: 0px;
  height: 124px;
  opacity: 1;
  border: 1px solid #ffffff;
  z-index: 199;
}
.xin {
  position: absolute;
  top: 130px;
  left: 193px;
  width: 17px;
  height: 17px;
  background-image: url("~@/assets/images/index/xin5.png");

  background-repeat: no-repeat;
  background-position: center center;
  z-index: 199;
}
.right {
  position: absolute;
  left: 218px;
  top: 138px;
  width: 1702px;
  height: 0px;
  opacity: 1;
  border: 1px solid #ffffff;
  z-index: 199;
}
.bottom {
  position: absolute;
  left: 200px;
  top: 151px;
  width: 0px;
  min-height: 795px;
  opacity: 1;
  border: 1px solid #ffffff;
  z-index: 199;
}
.bottom2 {
  position: absolute;
  left: 200px;
  top: 151px;
  width: 0px;
  min-height: 502px;
  
  
  opacity: 1;
  border: 1px solid #ffffff;
  z-index: 199;
}
/deep/ .swiper-pagination-bullet{
  background: #ffffff;
}
</style>
