<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">INTERNAL CONTROL SYSTEM</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">内控制度</span>
            </div>
          </div>
        </div>
      </div>


      <div class="box box2">
        <!-- <div class="content" v-html="pageData.content"></div> -->
        <div class="matop">
          <div class="title">
            <span class="sp1">
              序号
            </span>
            <span class="sp2">
              制度名称
            </span>

          </div>
        </div>
        <div class="matop" v-for="(item, index) in pageData" :key="item.value + item.name" @click="godetails(item)">
          <div class="blue">
            <span class="sp1">
              {{ index+1 }}
            </span>
            <span class="sp2">
              {{ item.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../components/common/Layout";
import { getSystem } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getSystem();
      console.log(res)
      this_.pageData = res.data;
    },
    dowload(e){
      let this_ = this
      window.open(this_.backurl+e.pdf_file, '_blank')
    },
    godetails(e){
      window.open(e.link, '_blank')
      // this.$router.push({path:'/InvestorSystemDetails',query: {nid:e.id}})
    }
  },
};
</script>
<style lang="scss" scoped>
.matop{
  
  .title{
    padding: 0 20px;
    background: #0864C6;
    color: #ffffff;
    font-size: 20px;
    display: flex;
    .sp1 {
      padding: 20px 0;
      display: inline-block;
      text-align: center;
      width: 240px;
      border-right: 5px solid #ffffff;
    }

    .sp2 {
      padding: 20px 0;
      display: inline-block;
      text-indent: 80px;
      flex: 1;
    }
  }
  .blue{
    margin-top: 5px;
    padding: 0 20px;
    color: #0864C6;
    background: #e0e0e0;
    font-size: 20px;
    display: flex;
    cursor:pointer;
    .sp1 {
      padding: 20px 0;
      display: inline-block;
      text-align: center;
      width: 240px;
      border-right: 5px solid #ffffff;
    }

    .sp2 {
      padding: 20px 0;
      display: inline-block;
      text-indent: 80px;
      flex: 1;
    }
  }
}
</style>
<style scoped>
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
}
.demo .title {
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  letter-spacing: 4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;
}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2 {
  padding-top: 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3 {
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.content{
    color: #000000;
}
/deep/ .content p{
    color: #000000;
}
.demo {
  text-align: justify;
}
</style>