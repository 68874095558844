<template>
  <Layout>
    <div class="demo">
      <div class="describe" v-html="content.describe">
           
      </div>
      <div class="box">
        <div class="y-title">{{content.en_title}}</div>
        <div class="z-title">
          <span class="sp1"></span>
          <span class="sp2"></span>
          <span class="sp3">{{content.title}}</span>
        </div>
      </div>
      <div class="box box2" style="padding-top: 0;">
        <div class="content" v-html="content.section1_content"></div>
      </div>

      <!-- <div class="box" style="padding-bottom: 100px;">
        <div class="con-box">
          <div class="content content1">{{ content.section2_content }}</div>
          <div class="vid">
            <video v-if="content.video_url" width="100%" height="100%" controls>
              <source
                :src="content.video_url"
                type="video/mp4"
              />
              <source
              :src="content.video_url"
                type="video/ogg"
              />
              <source
              :src="content.video_url"
                type="video/webm"
              />
              <object
                :data="content.video_url"
                width="100%"
                height="100%"
              >
                <embed
                :src="content.video_url"
                  width="100%"
                  height="100%"
                />
              </object>
            </video>
          </div>
        </div>
        
      </div> -->
    </div>
    
  </Layout>
</template>


<script>
import Layout from "../components/common/Layout";
import { getAboutJl } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  
  data() {
    return {
      content: {},
      ackurl: "https://jl.szhknet.cn",
    };
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let this_ = this
      let res = await getAboutJl()
      
      this_.content = res.data
    },
  },
};
</script>
<style scoped>

.bg-pos {
  position: relative;
}
.pos {
  position: absolute;
  right: 80px;
  top: 0;
  width: 427px;
  height: 89px;
  padding: 20px;
  background: #0864c6;
  border-radius: 0px 0px 0px 0px;
  font-size: 14px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo {
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}
.demo .title-box {
  display: flex;
  
}
.demo .title {
  width: 430px;
  margin-right: 136px;
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  line-height: 60px;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin-bottom: 69px;
}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.demo {
  
  position: relative;
  background-image: url("~@/assets/images/jinluUnderstand/dt1.png");

  background-repeat: no-repeat;
  background-position: top center;
}
.describe {
  position: absolute;
  right: 200px;
  top: 0;
  width: 759px;
  /* height: 158px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 30px 137px; */
  padding: 30px 90px;
  background: #0864C6;
  border-radius: 0px 0px 0px 0px;
  font-size: 24px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  
}
.content{
  font-size: 16px;
font-family: SourceHanSansSC-Light-GBpc-EUC-H-, SourceHanSansSC-Light-GBpc-EUC-H;
font-weight: normal;
color: #212121;
line-height: 42px;

text-align: justify;
}
.con-box{
  display: flex;
  justify-content: space-between;
}
  
    
.con-box .content1{
  width:430px;
}
.con-box .vid{
  width:816px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  
}
.content p{
  font-size: 16px !important;
  font-family: SourceHanSansSC-Light-GBpc-EUC-H- , SourceHanSansSC-Light-GBpc-EUC-H !important;
  font-weight: normal !important;
  color: #212121 !important;
  line-height: 42px !important;
}
.content p{}
.demo {
  text-align: justify;
}
</style>