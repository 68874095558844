<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">PRODUCT INTRODUCTION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">产品介绍</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2" v-for="(item, index) in pageData" :key="item.id">
        <div class="phnav">
          <div v-for="(item2, index2) in item.sub_category" :key="item2.id" :class="indexList['index'+index] == index2 ? 'focus': 'nav2'" @click="postpre(item2.id,index,index2)" >
            {{item2.name}}
          </div>
        </div>
        <div>
          <div class="introduce2" v-if="prductList['list'+ index] && prductList['list'+ index].length>0">
            <div style="position: fixed;color: transparent;display: none;">{{ prductList['list'+ index] }}</div>
              <div class="cpbox" v-for="(item2, index2) in prductList['list'+ index]" 
              :key="item2.id">
                <div class="imgbox2">
                  <img class="img1" :src="backurl + item2.finished_image">
                  <img class="img2" :src="backurl + item2.thumb_image">
                </div>
                
                <div class="tit">{{ item2.name }}</div>
              </div>
              <div class="listnav" v-if="prductList2['list'+ index]">
              
              <div class="left" >
                <img @click="onleft(index)" src="@/assets/images/index/left.png" alt="" />
              </div>
              <div
              v-for="(item2, index2) in prductList2['list'+ index]" 
              :key="item2.id"
              >
              
              <div v-if="index2!=0 && index2 % 4 == 0" :class="rlIndex == (index2/4) ? 'rd2': 'rd'">
                {{index2/4}}
              </div>
              </div>
              <div class="right">
                <img @click="onright(index)" src="@/assets/images/index/right.png" alt="" />
              </div>
            </div>
          
          </div>
          <div class="introduce2" v-else>
            <div class="wu">无数据</div>
          </div>
        </div>
      </div>

      <!-- <div class="box2" v-for="(item, index) in pageData" :key="item.id" >
        <span style="color:transparent;width:0px;height:0;float: left;">{{ index }}</span>
          
        <div class="product-nav">
          <div class="nav focus">{{item.name}}</div>
          <div class="nav"  v-for="(item2, index2) in item.sub_category" :key="item2.id" @click="postpre(item2.id,index)">
            {{item2.name}}
          </div>
        </div>
        <div class="introduce" v-if="prductList['list'+ index] && prductList['list'+ index].length>0">
          <div style="position: fixed;color: transparent;">{{ prductList['list'+ index] }}</div>
          <div 
            
            class="introduce-nav"
            v-for="(item2, index2) in prductList['list'+ index]" 
            :key="item2.id"
            
            
            >
            <div class="bigimg">
              <img src="@/assets/images/index/jsd.png" alt="" />
            </div>
              <img :src="backurl + item2.thumb_image" />
              <p class="p1">{{ item2.name }}</p>
            </div>
          <div class="listnav" v-if="prductList2['list'+ index]">
            
            <div class="left" >
              <img @click="onleft(index)" src="@/assets/images/index/left.png" alt="" />
            </div>
            <div
            v-for="(item2, index2) in prductList2['list'+ index]" 
            :key="item2.id"
            
            >
            <div class="rd" v-if="index2 % 6 == 0"></div>
            </div>
            <div class="right">
              <img @click="onright(index)" src="@/assets/images/index/right.png" alt="" />
            </div>
          </div>
        </div>
        <div class="introduce2" v-else>
          <div class="wu">无数据</div>
        </div>
        
      </div> -->
      <!-- <div class="box2" v-for="(item, index) in pageData" :key="item.id" v-show="index==1">
        <span style="color:transparent;width:0px;height:0;float: left;">{{ index }}</span>
          
        <div class="product-nav">
          <div class="nav focus">{{item.name}}</div>
          <div class="nav"  v-for="(item2, index2) in item.sub_category" :key="item2.id" @click="postpre(item2.id,index)">
            {{item2.name}}
          </div>
        </div>
        <div class="introduce" v-if="prductList['list'+ index] && prductList['list'+ index].length>0">
          <div style="position: fixed;color: transparent;">{{ prductList['list'+ index] }}</div>
          <div 
            
            class="introduce-nav"
            v-for="(item2, index2) in prductList['list'+ index]" 
            :key="item2.id"
            
            
            >
            <div class="bigimg">
              <img src="@/assets/images/index/jsd.png" alt="" />
            </div>
              <img :src="backurl + item2.thumb_image" />
              <p class="p1">{{ item2.name }}</p>
            </div>
          <div class="listnav" v-if="prductList2['list'+ index]">
            
            <div class="left" >
              <img @click="onleft(index)" src="@/assets/images/index/left.png" alt="" />
            </div>
            <div
            v-for="(item2, index2) in prductList2['list'+ index]" 
            :key="item2.id"
            
            >
            <div class="rd" v-if="index2 % 6 == 0"></div>
            </div>
            <div class="right">
              <img @click="onright(index)" src="@/assets/images/index/right.png" alt="" />
            </div>
          </div>
        </div>
        <div class="introduce" v-else>
          <div class="wu">无数据</div>
        </div>
        
      </div>
     

      <div class="box2" v-for="(item, index) in pageData" :key="item.id" v-show="index!=1">
        <span style="color:transparent;width:0px;height:0;float: left;">{{ index }}</span>
          
        <div class="product-nav">
          <div class="nav focus">{{item.name}}</div>
          <div class="nav"  v-for="(item2, index2) in item.sub_category" :key="item2.id" @click="postpre(item2.id,index)">
            {{item2.name}}
          </div>
        </div>
        <div class="introduce" v-if="prductList['list'+ index] && prductList['list'+ index].length>0">
          <div style="position: fixed;color: transparent;">{{ prductList['list'+ index] }}</div>
          <div 
            
            class="introduce-nav"
            
            v-for="(item2, index2) in prductList['list'+ index]" 
            :key="item2.id">
            <div class="bigimg" v-show="showBig">
              <img src="@/assets/images/index/jsd.png" alt="" />
            </div>
              <img :src="backurl + item2.thumb_image" />
              <p class="p1">{{ item2.name }}</p>
            </div>
          <div class="listnav" v-if="prductList2['list'+ index]">
            
            <div class="left">
              <img @click="onleft(index)" src="@/assets/images/index/left.png" alt="" />
            </div>
            <div
            v-for="(item2, index2) in prductList2['list'+ index]" 
            :key="item2.id"
            
            >
            <div class="rd" v-if="index2 % 6 == 0"></div>
            </div>
            <div class="right">
              <img @click="onright(index)" src="@/assets/images/index/right.png" alt="" />
            </div>
          </div>
        </div>
        <div class="introduce" v-else>
          <div class="wu">无数据</div>
        </div>
        
        
      </div> -->
    </div>
    



  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getProductPategory, getPro } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      indexList:{},
      prductList:{},
      prductList2:{},
      prductListNum:[],
      rlIndex: 1,
      showBig: true,
      x: 0,
      y: 0
    };
  },
  directive: {
    mouseover: {
      inserted: function (el, binding) {
        console.log(el)
        el.addEventListener('mouseover', function () {
          binding.value(true);
        });
      }
    
    },
    mouseout: {
      inserted: function (el, binding) {
        el.addEventListener('mouseout', function () {
          binding.value(false);
        });
      }
    }
  
  },
  created() {
    
  },
  mounted(){
    this.init();
  },
  watch:{

  },
  methods: {
    handleMouseMove: function(event) {
      this.showBig = true;
      this.x = event.clientX;
      this.y = event.clientY;
    },
    showBigImage: function(event) {
      console.log(1233)
      this.showBig = true;
      this.x = event.clientX;
      this.y = event.clientY;
    },
    hideBigImage: function() {
      console.log(1233)
      this.showBig = false;
    },
    async init() {
      let this_ = this;
      let res = await getProductPategory();
      console.log(res)
      this_.pageData = res.data;
      for(let i=0;i<this_.pageData.length;i++){
        
        this_.postpre(this_.pageData[i].sub_category[0].id, i)
        this_.prductListNum[i] = 1
      }
      console.log('this_.prductList',this_.prductList)
      console.log('this_.prductList2',this_.prductList2)
    },
    async postpre(e,index,index2){
      console.log('postpre')
      let this_ = this;
      let res = await getPro({pid:e});
      this_.rlIndex = 1
      if(index2){
        this_.$set(this_.indexList, "index" + index, index2)
        
      }else{
        this_.$set(this_.indexList, "index" + index, 0)
      }
      
      this_.$set(this_.prductList2, "list" + index, JSON.parse(JSON.stringify(res.data)))
      // this_.prductList2["list" + index] = JSON.parse(JSON.stringify(res.data))
      // this_.prductList["list" + index] = JSON.parse(JSON.stringify(res.data.slice(0,6)))
      this_.$set(this_.prductList, "list" + index, JSON.parse(JSON.stringify(res.data.slice(0,5))))
      
    },
    navclick(){

    },
    onleft(index,index2){

      let this_ = this
      console.log(this_.prductList['list'+ index])
      console.log(this_.prductListNum[index])
      console.log(Math.ceil(this_.prductList2['list'+ index].length) / 5 < this_.prductListNum[index])
      if(this_.prductListNum[index]!=0){
        this_.prductListNum[index] = this_.prductListNum[index]-1
        this_.rlIndex = this_.prductListNum[index]
        
        console.log('(this_.prductListNum[index]-1)*4', (this_.prductListNum[index]-1)*5)
        this_.$set(this_.prductList, "list" + index, JSON.parse(JSON.stringify(this_.prductList2['list'+ index].slice((this_.prductListNum[index]-1)*5,this_.prductListNum[index]*5))))

        
      }
    },
    onright(index,index2){
      
      let this_ = this
      console.log(Math.ceil(this_.prductList2['list'+ index].length/5) < this_.prductListNum[index])
      console.log('index', index)
      console.log('index2', index2)
      console.log(this_.prductList2['list'+ index])
      console.log(this_.prductListNum[index])
      if(Math.ceil(this_.prductList2['list'+ index].length/5) > this_.prductListNum[index]){
        this_.prductListNum[index] = this_.prductListNum[index]+1
        this_.rlIndex = this_.prductListNum[index]
        console.log('(this_.prductListNum[index]-1)*4', (this_.prductListNum[index]-1)*5)
        this_.$set(this_.prductList, "list" + index, JSON.parse(JSON.stringify(this_.prductList2['list'+ index].slice((this_.prductListNum[index]-1)*5,this_.prductListNum[index]*5))))
        console.log(this_.prductList['list'+ index])
      }
      //this_.prductList["list" + index] = JSON.parse(JSON.stringify(res.data.slice(0,6)))
    }
  },
};
</script>
<style scoped>
.motop{
  margin: 50px 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 70px;
}

.product-nav{
  width: 220px;
  text-align: center;
}
.nav {
  cursor:pointer;
  display: flex;
    justify-content: center;
  padding: 20px 0px;
  background: #f8f8f8;
  font-size: 20px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  margin-bottom: 10px;
}
.nav.focus {
  font-size: 25px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  background: #0864c6;
}
.p1{
  text-align: center;
  font-size: 20px;
  font-family: SourceHanSansSC-Regular-GBpc-EUC-H-, SourceHanSansSC-Regular-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
}
.listnav{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left, .right{

}
.left img, .right img{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.rd{
  background: #EFEFEF;
  margin: 0 5px;
  padding: 15px 25px;
  background: #fff;
  vertical-align: top;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  
}
.rd2{
  background: #EFEFEF;

  margin: 0 5px;
  padding: 15px 25px;
  
  vertical-align: top;
  display: inline-block;
  font-size: 25px;
  

  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  
}

.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
  flex-direction:column;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.introduce-nav{
  margin-left: 20px;
  margin-bottom: 20px;
  width: 310px;
  /* height: 270px; */
  background: #F8F8F8;
  border-radius: 0px 0px 0px 0px;
}
.introduce-nav .bigimg{
  position: absolute;
  width: 950px;
  height: 550px;
  display: none;
}
.introduce-nav:hover .bigimg{
  left: 0;
  
  display: block;
}
.introduce{
  flex: 1;
  display: flex;
  flex-wrap:wrap;
}


.wu{
  font-size: 60px;
    font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
    font-weight: normal;
    color: #efefef;
    letter-spacing: 4px;
    margin: 0 auto;
    line-height: 280px;
}

.introduce-nav:hover{
  transform: scale(1.2);
  cursor:pointer;
}

.phnav{
  display: flex;
  justify-content: center;
  width: 100%;
}
.phnav .nav2{
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  background: #f8f8f8;
  font-size: 20px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  margin-bottom: 10px;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.phnav .nav2:hover{
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  background: #f8f8f8;
  font-size: 20px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  background: #0864c6;
}
.phnav .focus {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  background: #f8f8f8;
  font-size: 20px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 25px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  background: #0864c6;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.introduce2{
  margin: 10px 0;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.introduce2 .cpbox{
  cursor: pointer;
  display: flex;
  flex-direction:column;
  width: 20%;
  height: 600px;
}
.introduce2 .cpbox:hover{
  border: 1px solid #0864C6;
}
.introduce2 .cpbox .tit{
  text-align: center;
    font-size: 20px;
    font-family: SourceHanSansSC-Regular-GBpc-EUC-H-, SourceHanSansSC-Regular-GBpc-EUC-H;
    font-weight: normal;
    color: #0864C6;
}
.imgbox2{
  height: 550px;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;

}
/*.introduce2 .cpbox .img1{
  display: none;
}*/
.introduce2 .cpbox .img2{
  
}
.cpbox:hover .img1{
  display: block;
}
.imgbox2 .img1{
  height: 250px;
  width: 310px;
}
.imgbox2 .img2{
  height: 250px;
  width: 310px;
}
.demo {
  text-align: justify;
}
</style>