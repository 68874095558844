<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">NEWS AND INFORMATION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">新闻资讯</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="new-list" v-for="(item, index) in pageData" :key="item.id" @click="godetails(item)">
          <div class="left">
            <p class="p1">
              {{ item.publishtime.split("-")[1] + '-' + item.publishtime.split("-")[2] }}
            </p>
            <p class="p2">
              {{ item.publishtime.split("-")[0] }}
            </p>
          </div>
          <div class="right p4">
            <p class="p3"><b style="font-size: 25px;margin-right: 15px;">{{ item.catename }}</b>{{ item.title }}</p>
          </div>
        </div>
        <div class="pageNum" v-if="pageNum >= 5">
          <div class="left">
            <img @click="onleft()" src="@/assets/images/index/left.png" alt="" />
          </div>
          <div v-for="count in pageNum">
            <div v-if="count != 0 && count % 5 == 0" :class="rlIndex == (count / 5) ? 'rd2' : 'rd'">
              {{ count / 5 }}
            </div>
          </div>
          
          <div class="right">
            <img @click="onright()" src="@/assets/images/index/right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getNews } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      page: 1,
      pageNum: 1,
      rlIndex: 1
    };
  },
  created() {
    this.init();
    
  },
  beforeCreate(){
    // this_.$route.meta.title = '测试修改标题'
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getNews({ page: this_.page });
      console.log(res)
      this_.pageData = res.data;
      
      this_.pageNum = res.count
      console.log('this_.$route', this_.$route)
      // document.title = '新闻'
      // this_.pageNum = 8
    },
    godetails(e) {
      window.location.href = e.link_url
      // this.$router.push({path:'/NewDetails',query: {nid:e.id}})
    },
    onleft() {
      const this_ = this
      if(this_.page>1){
        this_.page = this_.page - 1
        this_.rlIndex = this_.page
        this_.init()
      }
    },
    onright() {
      const this_ = this
      if(Math.ceil(this_.pageNum/5) > this_.page){
        this_.page = this_.page + 1
        this_.rlIndex = this_.page
        this_.init()
      }
    },
  },
};
</script>
<style scoped>
.main-content {
  background: #ffffff;
}

.bg {
  background: #ffffff;
  padding: 80px;
}

.row-box {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 7px 7px 13px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 40px;
}

.rd {
  background: #EFEFEF;
  margin: 0 5px;
  padding: 15px 25px;
  background: #fff;
  vertical-align: top;
  display: inline-block;
  font-size: 25px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;

}

.rd2 {
  background: #EFEFEF;

  margin: 0 5px;
  padding: 15px 25px;

  vertical-align: top;
  display: inline-block;
  font-size: 25px;


  cursor: pointer;
  box-sizing: border-box;
  text-align: center;

}

.p1 {
  padding: 0;
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: center;
  font-size: 35px;


  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4D4D4D;

}

.pageNum {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left,
.right {}

.pageNum .left img,
.pageNum .right img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.p2 {

  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: center;

  font-size: 21px;
  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4D4D4D;
}

.p3 {

  padding: 0;
  padding: 0px 0px 0px 20px;
  margin: 30px;
  font-size: 20px;

  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;

}

.p4 {
  border-left: 1px solid #0864C6;
}

.new-list:hover p {
  color: #0864C6;
}

.new-list:hover {
  cursor: pointer;
}

.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}

.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}

.demo .title {}

.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing: 4px;
}

.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;

}

.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}

.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}

.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}

.box2 {
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}

.box3 {
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.new-list {
  width: 1382px;
  height: 130px;
  background: #FFFFFF;
  box-shadow: 10px 10px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 30px;
  padding: 20px 0;
  display: flex;
}

.new-list .right {
  width: 81%;
}

.new-list .left {
  width: 19%;
}

.demo {
  text-align: justify;
}</style>