<template>
  <div>
    <!-- 
    <ul class="mainmenu">  
    <li class="nav-has" v-for="(item, index) in headerNav" :key="item.id + item.pid">
        <a :href="item.link">
          {{ item.title }}
          <Icon name="plus" class="collapse-icon plus"/>
          <Icon name="minus" class="collapse-icon minus"/>
        </a>
        <ul class="submenu" v-if="item.children && item.children.length>0">
          <li v-for="(item2, index2) in item.children" :key="item2.id + item2.pid"><router-link :to="item2.title">{{item2.title}}</router-link></li>
        </ul>
      </li>
    </ul> -->
    <!-- <ul class="mainmenu">
      <li class="nav-has">
          <a href="/">
              首页
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
      </li>
      <li class="nav-has">
          <a href="/JinluUnderstandProfile">
              了解金禄
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/JinluUnderstandProfile">公司简介</router-link></li>
              <li><router-link to="/JinluUnderstandHistory">发展历程</router-link></li>
              <li><router-link to="/JinluUnderstandCulture">企业文化</router-link></li>
              <li><router-link to="/JinluUnderstandHonor">资质荣誉</router-link></li>
              <li><router-link to="/JinluUnderstandContact">联系方式</router-link></li>
          </ul>
      </li>
      <li class="nav-has">
          <a href="/ProductIntroduce">

              产品技术
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/ProductIntroduce">产品介绍</router-link></li>
              <li><router-link to="/ProductAbility">制程能力</router-link></li>
              <li><router-link to="/ProductPatent">专利授权</router-link></li>
              <li><router-link to="/ProductSituation">设备情况</router-link></li>
          </ul>
      </li>
      <li class="nav-has">
          <a href="/SubsidiaryHuBei">
              子公司
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/SubsidiaryHuBei">湖北金禄</router-link></li>
              <li><router-link to="/SubsidiaryHongKong">香港凯美诺</router-link></li>
              
          </ul>
      </li>
      <li class="nav-has">
          <a href="#">
              社会责任
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/ResponsibilityEnvironment">环境保护</router-link></li>
              <li><router-link to="/ResponsibilityProduction">安全生产</router-link></li>

          </ul>
      </li>
      <li class="nav-has">
          <a href="#">
              新闻
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/NewList">新闻资讯</router-link></li>
              
          </ul>
      </li>
      <li class="nav-has">
          <a href="#">
              人力资源
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/ManpowerIdea">人才理念</router-link></li>
              <li><router-link to="/ManpowerTreatment">福利待遇</router-link></li>
              <li><router-link to="/ManpowerDevelopment">晋升发展</router-link></li>

          </ul>
      </li>
      <li class="nav-has">
          <a href="#">
              投资者关系
              <Icon name="plus" class="collapse-icon plus"/>
              <Icon name="minus" class="collapse-icon minus"/>
          </a>
          <ul class="submenu">
              <li><router-link to="/InvestorPerformancereport">业绩报告</router-link></li>
              <li><router-link to="/InvestorQuotation">股市行情</router-link></li>
              <li><router-link to="/InvestorPeriodicreport">定期报告</router-link></li>
              <li><router-link to="/InvestorInterimreport">临时报告</router-link></li>
              <li><router-link to="/InvestorGovernance">公司治理</router-link></li>
              
              <li><router-link to="/InvestorReturn">股东回报</router-link></li>
          </ul>
      </li>
    </ul> -->
    <ul class="nav">
      <li class="nav-has" v-for="(item, index) in headerNav" :key="item.id + item.pid + 'nav1'" :class="getmake(item)">



        <router-link class="a1" :to="item.link">{{ item.title }}</router-link>
        <ul class="submenu submenu2" v-if="item.children && item.children.length > 0">

          <li v-for="(item2, index2) in item.children" :key="item2.id + item2.pid + 'nav2'"><router-link :to="item2.link"
              :class="{ makeColor2: item2.link.indexOf(dtshow) >= 0 }">{{ item2.title }}</router-link></li>

        </ul>
      </li>

    </ul>

  </div>
</template>
<style scoped>
ul li {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav {
  display: flex;

}

.nav .nav-has {
  width: 95px;
  margin: 0 8px;
  text-align: center;
  position: relative;
}

.nav .nav-has .a1 {
  font-size: 16px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #F5F5F5;
  line-height: 19px;
  padding-bottom: 10px;
  color: #F5F5F5;

}

.nav .nav-has .a1:hover {
  border-bottom: 1px solid #00C1C4;
}

.submenu {
  margin: 0;
  padding: 0;
  position: absolute;
  margin-top: 0px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0px 0px 0px 0px;
  z-index: 999;

}

.nav .nav-has .submenu li a {
  font-size: 14px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #1A1A1A;
  line-height: 17px;

}

.submenu {
  /* display: none; */
  transition: max-height 0.7s;
  max-height: 0;
  width: 95px;
  overflow: hidden;
}

.nav .nav-has .submenu:hover {
  display: flex;
  flex-direction: column;
  width: 95px;
  text-align: center;
}

.nav .nav-has .submenu:hover .submenu2 {
  display: block;
  flex-direction: column;
  width: 95px;
  text-align: center;
}

.nav .nav-has:hover .submenu {
  display: block;
  flex-direction: column;
  max-height: 500px;
  text-align: center;
}

.nav .nav-has:hover .makeColor .a1 {
  border: none;
}

.makeColor .a1 {
  border-bottom: 1px solid #00C1C4;
}

.nav .nav-has .submenu .makeColor2 {
  color: #0864C6;
}

.nav .nav-has .submenu a:hover {
  color: #0864C6;
}
</style>
<script>
import Icon from "../../icon/Icon";
export default {
  name: 'Nav',
  components: { Icon },
  props: {
    getpic: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      headerNav: [],
      dtshow: '',
      path: '',
      returnedItem: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      dtshowName: '',
    }
  },
  created () {
    this.headerNav = JSON.parse(sessionStorage.getItem('header_nav'))
    this.dtshow = this.$route.name;
    this.path = this.$route.path
    console.log('headerNav', this.headerNav)
    var returnedItem; //定义一个不不赋值的变量
    this.dtshowName = this.$route.name + '.png'

    var item = this.getfind(this.headerNav, this.dtshow);

    this.getpic(this.returnedItem)
  },
  methods: {
    getfind (arr, id) {
      let this_ = this
      arr.forEach((item) => { //利用foreach循环遍历

        if (item.link.indexOf(id) >= 0)//判断递归结束条件
        {
          this_.returnedItem = item
          return item;
        }
        else if (item.children && item.children.length > 0) //判断chlidren是否有数据
        {
          this_.getfind(item.children, id);  //递归调用                      
        }
      })
    },
    getmake (list) {
      console.log('this.dtshow', this.dtshow)
      console.log('this.path',this.path)
      // if(this.dtshow=='NewDetails' && this.path == 'NewList'){
      //   cons
      //   if(list.link == '/NewDetails'){
      //         return 'makeColor'
      //     }
      // }
      if (this.path == '/' && this.dtshow == 'Demo') {
        console.log(1111111111)
        if (list.link == '/') {
          return 'makeColor'
        }

      } else if (this.path == '/NewList' && this.dtshow == 'NewList') {
        if (list.link == '/NewList') {
          return 'makeColor'
        }
      } else if (this.path == '/NewDetails' && this.dtshow == 'NewDetails') {
        console.log("list.link", list.link)
        if (list.link == '/NewList') {
          return 'makeColor'
        }
      } else if(this.path == '/InvestorSystemDetails' && this.dtshow == 'InvestorSystemDetails'){
        console.log("list.link", list.link)
        if (list.link == '/InvestorPerformancereport') {
          return 'makeColor'
        }
      }else {

        if (list.children) {
          console.log(22222222)
          let tf = ''
          for (let i = 0; i < list.children.length; i++) {
            if (list.children[i].link.indexOf(this.dtshow) >= 0) {
              return 'makeColor'
            }
          }

          console.log('list', list)
          return tf
        } else {
          console.log(333333333)
        }
        // if(itme.)

      }



    }
  }
}
</script>