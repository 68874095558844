<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">ORGANIZATION CHART</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">组织架构</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2" style="padding-bottom: 0">
        <img :src="governData.org_image" />
      </div>
      <!-- <div class="box">
        <div class="title-box">
          <div class="title">
            
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">各职能主要职责说明如下</span>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="box2" style="padding-bottom:0;">
        <div class="list">
          <div class="list-left">
            审计部
          </div>
          <div class="list-right">在董事会审计委员会的领导下负责开展公司内部(包括对子公司)的审计工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            市场营销部
          </div>
          <div class="list-right">负责市场研究、客户开拓、订单承接及跟踪等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            研发部
          </div>
          <div class="list-right">负责产品研发、工艺管理、工程管理等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            计划部
          </div>
          <div class="list-right">负责评估订单调配物料组织生产、成品包装、物料仓和成品仓管理等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            生产部
          </div>
          <div class="list-right">负责生产各工序管理、设备管理及维修等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            品管部
          </div>
          <div class="list-right">负责品质检验、质量管控、体系管理、客诉服务等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            采购部
          </div>
          <div class="list-right">负责原材料、辅料、机器设备、办公用品等采购工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            市场营销部
          </div>
          <div class="list-right">负责市场研究、客户开拓、订单承接及跟踪等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            财务部
          </div>
          <div class="list-right">负责会计核算、报表编制、资金管理、税务等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            董事会办公室
          </div>
          <div class="list-right">在董事会秘书的领导下负责股东大会及董事会的会务、股权管理、股东联络、与证券监管部门及中介机构的沟通协调等工作;</div>
        </div>
        <div class="list">
          <div class="list-left">
            总经办
          </div>
          <div class="list-right">负责协助总经理处理日常经营相关事务;</div>
        </div>
      </div> -->


      <div v-for="(item, index) in pageData" :key="index">
        <div class="box">
          <div class="title-box">
            <div class="title">
              <div class="y-title">{{ item.department_en }}</div>
              <div class="z-title">
                <span class="sp1"></span>
                <span class="sp2"></span>
                <span class="sp3">{{ item.department }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="box2">
          <div
            class=""
            v-for="(item2, index2) in item.sub_department"
            :key="index2 + 'index2'"
          >
            <div class="list" v-if="item2.manager.length != 0">
              <div
                class="list-left2"
                v-for="(item3, index3) in item2.manager"
                :key="index3 + 'index3'"
              >
                <div class="list-top" @click="searchDepart($event, item3)">
                  <span class="its1">{{ item2.department }}</span>
                  <span class="its2">{{ item3.name }}</span>
                </div>

                <div class="list-bottom" v-if="item3.describe_content" v-html="item3.describe_content">
                  <!-- {{ item3.describe_content }} -->
                </div>
              </div>

              <!-- <div class="list-right"  v-for="(item3, index3) in item2.manager" :key="index3 + 'index3'">
                <span>{{item3}} <img src="@/assets/images/investor/san.png" /> </span>
                
              </div> -->
            </div>
            <!-- <div class="list">
              <div class="list-left">
                董事长
              </div>
              <div class="list-right">
                <span>李继林 <img src="@/assets/images/investor/san.png" /> </span>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">CONFERENCE OPERATION SITUATION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">三会运作情况</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2" v-html="governData.the_briefing_content">
        
        <!-- <div class="one"></div>
        <div class="two">
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-2"></div>
        </div>
        <div class="two">
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-2"></div>
        </div> -->
      </div>

      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">INDEPENDENT DIRECTOR COLUMN</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">独立董事专栏</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="" v-for="(item5, index) in governData.dudong" :key="item5.id">
          <div class="list2" @click="dowload(item5)">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">{{item5.title}}</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right">
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p3">{{item5.download_num}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- <div class="box2">
        <div
          class="new-list"
          v-for="(item, index) in pageData2"
          :key="item.id"
          @click="godetails(item)"
        >
          <div class="left">
            <p class="p1">
              {{
                item.publishtime.split("-")[1] +
                "-" +
                item.publishtime.split("-")[2]
              }}
            </p>
            <p class="p2">
              {{ item.publishtime.split("-")[0] }}
            </p>
          </div>
          <div class="right p4">
            <p class="p3">{{ item.title }}</p>
          </div>
        </div>
      </div> -->

      <div class="box3">
        <div class="pos">
          <p>独立董事邮箱公布</p>
          <p>
            <a
              :href="'mailto:' + governData.director_email"
              >{{ governData.director_email }}</a
            >
          </p>
        </div>
      </div>

      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">SUPERVISORY BOARD COLUMN</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">监事会专栏</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="box2">
        <div
          class="new-list"
          v-for="(item, index) in pageData3"
          :key="item.id"
          @click="godetails(item)"
        >
          <div class="left">
            <p class="p1">
              {{
                item.publishtime.split("-")[1] +
                "-" +
                item.publishtime.split("-")[2]
              }}
            </p>
            <p class="p2">
              {{ item.publishtime.split("-")[0] }}
            </p>
          </div>
          <div class="right p4">
            <p class="p3">{{ item.title }}</p>
          </div>
        </div>
        
      </div> -->
      <div class="box2">
        <div class="" v-for="(item5, index) in governData.jianshi" :key="item5.id">
          <div class="list2" @click="dowload(item5)">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">{{item5.title}}</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right">
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p3">{{item5.download_num}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="box3">
        <div class="pos">
          <p>监事邮箱公布</p>
          <p>
            <a
              :href="'mailto:' + governData.supervisor_email"
              >{{ governData.supervisor_email }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getDerectors, geinvestorgetNews, getTem, getDownloadNumber, getInvestorOrgConfig } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      pageData2: [],
      pageData3: [],
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      pageData4: [],
      governData: {}
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res2 = await getInvestorOrgConfig();
      this.governData = res2.data
      let res = await getDerectors();
      // let res2 = await geinvestorgetNews({ cateid: 5 });
      // let res3 = await geinvestorgetNews({ cateid: 6 });
      

      console.log(res);
      

      this_.pageData = res.data;
      // this_.pageData2 = res2.data;
      // this_.pageData3 = res3.data;

      // let res4 = await getTem();
      // console.log(res)
      // this_.pageData4 = res4.data;
    },
    godetails(e) {
      this.$router.push({ path: "/NewDetails", query: { nid: e.id } });
    },
    dowload(e) {
      let this_ = this;
      e.download_num = e.download_num++;
      window.open(e.link_url, "_blank");
    },
    searchDepart(e, it) {
      console.log(e, it);
      if (!it.describe) return;
      console.log(e.currentTarget.nextElementSibling);
      let ho = e.currentTarget.nextElementSibling;
      console.log(e.currentTarget.nextElementSibling.style.display);
      if (e.currentTarget.nextElementSibling.style.display == "flex") {
        e.currentTarget.nextElementSibling.style.display = "none";
      } else {
        e.currentTarget.nextElementSibling.style.display = "flex";
      }
      // display: flex;
    },
  },
};
</script>
<style scoped>
.pos {
  width: 1100px;
  padding: 45px 45px 45px 136px;
  background: #0864c6;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 45px;
  /* display: flex;

  align-items: center; */
}
.pos p {
  color: #ffffff;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 45px;
}
.pos p a {
  color: #ffffff;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 45px;
}

.dd {
  margin-bottom: 40px;
}
p {
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
}
.demo .title {
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  letter-spacing: 4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;
}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2 {
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3 {
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.list {
  display: flex;
}

.list {
  padding: 10px 30px 20px 0px;
}
.list .list-left {
  display: flex;
  background: #0864c6;
  padding: 10px 0;
  width: 144px;

  justify-content: center;
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 50px;
  margin-right: 20px;
}
.list .list-left2 {
  /* background */
}
.list .list-left2:hover .list-bottom {
  display: block;
}
.list .list-top {
  display: flex;
  align-items: center;
  padding: 10px 10px;

  width: 223px;
  height: 56px;
  justify-content: space-between;
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 56px;
  margin-right: 20px;
  background-image: url("~@/assets/images/dsbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left top;
  cursor: pointer;
}
.list .list-bottom {
  display: flex;
  padding: 10px 10px;

  width: 223px;
  /* height: 244px; */
  justify-content: space-between;
  font-size: 15px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  background-image: url("~@/assets/images/dsbg2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left top;
  display: none;
}
.its1 {
}
.its2 {
  margin-left: 20px;
  font-size: 17px;
}
.list .list-left img {
}
.list .list-left-con .p1 {
  font-size: 13px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4d4d4d;
}
.list .list-left-con .p2 {
  font-size: 10px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4d4d4d;
}
.list .list-right {
  display: flex;
  align-items: center;
  color: #000000;
}
.list .list-right span {
  text-align: center;
  margin-right: 5px;
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #000000;
}

.list .list-right img {
  margin-right: 30px;
  width: 12px;
  height: 12px;
}
.new-list:hover p {
  color: #0864c6;
}
.new-list:hover {
  cursor: pointer;
}
.new-list {
  width: 1382px;
  height: 130px;
  background: #ffffff;
  box-shadow: 10px 10px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 30px;
  padding: 20px 0;
  display: flex;
}
.new-list .right {
  width: 81%;
}
.new-list .left {
  width: 19%;
}
.new-list .p1 {
  padding: 0;
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: center;
  font-size: 35px;

  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4d4d4d;
}
.new-list .p2 {
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: center;

  font-size: 21px;
  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4d4d4d;
}
.new-list .p3 {
  padding: 0;
  padding: 0px 0px 0px 20px;
  margin: 30px;
  font-size: 20px;

  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4d4d4d;
}

.new-list .p4 {
  border-left: 1px solid #0864c6;
}
.one {
  background: #0864c6;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  padding: 0 20px;
  height: 95px;
}
.two {
  display: flex;
  height: 95px;
  color: #0864c6;
  background: #e0e0e0;
}
.two-1 {
  padding: 20px 0;
  display: inline-block;
  text-align: center;
  height: 95px;
  line-height: 95px;
  width: 240px;
  border-top: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
}
.two-2 {
  padding: 20px 0;
  display: inline-block;
  text-align: center;
  height: 95px;
  line-height: 95px;
  width: 240px;
  border-top: 5px solid #ffffff;
  border-right: 5px solid #ffffff;

  flex: 1;
}
.two-3 {
}


.list2{
  display: flex;
  
}
.list2{
  cursor:pointer;
}
.list2{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list2 .list-left{
  display: flex;
  align-items: center;
}
.list2 .list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list2 .list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list2 .list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list2 .list-right{
  display: flex;
  align-items: center;
}
.list2 .list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.list2 .p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.list2 .list:hover .list-left-con .p1{
    color: #0864C6;
  }
  .demo {
  text-align: justify;
}
</style>