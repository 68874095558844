<template>
  <div class="honor">
    
    <el-carousel height="556px" ref="carousel" style="width: 100%;" :interval="5000" arrow="never"
      indicator-position="none">
      <el-carousel-item class="honor-nav" v-for="(item, index) in getNewArray(pageData, 4)" :key="'it'+index">
        <div class="p4" v-for="(item2, index2) in item" :key="'it2'+index2">
          <div class="img" @click="gobigimg(index2+index*4)">
            <el-image 
            style="width: 100%;height: 100%;"
            :src="backurl + item2[imageTitle]"
           
            >
            </el-image>
          </div>

        </div>
      </el-carousel-item>
      
    </el-carousel>
<!-- <el-carousel-item class="honor-nav">

        :preview-src-list="[backurl + item[imageTitle]]"
        <div class="p4" v-for="(item, index) in pageData.slice(4)" :key="item.id">
          <div class="img" @click="gobigimg">
            <el-image 
              style="width: 100%;height: 100%;"
              :src="backurl + item[imageTitle]"
              
              >
            </el-image>
          </div>
          、
        </div>
      </el-carousel-item> -->
    
    <div class="left">
      <img @click="changePage('prev')" src="@/assets/images/jinluUnderstand/left.png" width="70" />
    </div>
    <div class="right">
      <img @click="changePage('next')" src="@/assets/images/jinluUnderstand/right.png" width="70" />
    </div>
    

    <!-- <div class="mode">
      <div id="modeclose" class="close"></div>
      
      <div class=""></div>
      <img :src="bigImg" />
    </div> -->

    <div id="wrapper" class="mode" v-if="dialogVisible">
      <div id="modeclose" class="close" @click="dialogVisible=false"></div>
      <div class="modcon" name="list" tag="ul" mode="out-in">
        <li v-for="(item,index) in bigImgList" :key="item.url">
          <img v-if="item.patent_image" :src="backurl + item.patent_image">
          <img v-if="item.license_image" :src="backurl + item.license_image">
        </li>
        <div class="imgnav">{{ iv + '/' + pageData.length }}</div>
      </div>
      
      <div href="javascript:;" id="arrLeft" @click="turnleft"></div>
      <div href="javascript:;" id="arrRight" @click="turnright"></div>
      <div class="j_bottom">
        
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'Carousel',
  props: {
    pageData: {
      type: Array,
      default: () => []
    },
    imageTitle: {
      type: String,
      default: 'license_image'
    },
    index: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      bigImg: '',
      dialogVisible: false,
      piclist: [
        // { url: require("@/assets/images/001.jpg") },
        // { url: require("@/assets/images/002.jpg") },
        // { url: require("@/assets/images/003.jpg") },

        // { url: require("@/assets/images/002.jpg") },
        // { url: require("@/assets/images/001.jpg") },
      ],
      //文件图片配置
      config: [
      ],
      previous: 0,
      ggd: 5,
      ggg: 0,
      now: Date.now(),
      bigImgList: [],
      iv: 1,
    };
  },
  created(){
    console.log('this.pageData', this.pageData)
  },
  mounted(){
    console.log('this.pageData2', this.pageData)
  },
  methods: {
    getNewArray(array, subGroupLength) {
      console.log('array', array)
      console.log('subGroupLength', subGroupLength)
      let index = 0;
      let newArray = [];
      while(index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
      }
      console.log('newArray', newArray)
      return newArray;
    },
    gobigimg(index){
      const this_ = this
      this_.iv = index + 1
      console.log('this2', this.pageData)

      let arrayA = this_.pageData.slice(0,index)
      let arrayB = this_.pageData.slice(index,this_.pageData.length)
      this_.bigImgList = arrayA.concat(arrayB)
      // this_.pageData.slice(0, 8)
      console.log(this_.bigImgList)
      this_.dialogVisible = true
    },
    changePage (event) {
      console.log('event', event)
      this.$refs.carousel[event]()
    },
    turnleft: function() {
      this.now = Date.now();
      if (this.now - this.previous > 1000) {
        
        if(this.iv==1){
          this.iv =  this.pageData.length
        }else{
          this.iv = this.iv - 1
        }
        this.bigImgList.push(this.bigImgList.shift())

       
      }
    },
    turnright: function() {
      this.now = Date.now();
      if (this.now - this.previous > 1000) {
        if(this.iv>=this.pageData.length){
          this.iv =  1
        }else{
          this.iv = this.iv + 1
        }
        this.bigImgList.unshift(this.bigImgList.pop());
        this.previous = this.now;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.imgnav{
  bottom: 0;
    position: absolute;
    /* top: 0; */
    font-size: 30px;
    width: 100%;
    text-align: center;
}
    
.mode{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99999;
}
.close{
  position: absolute;
  top: 70px;
  right: 70px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: url("~@/assets/images/btn_close.png") no-repeat center;
  background-size: 100% 100%;
}


.honor {
  padding-bottom: 144px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  position: relative;

  .honor-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img {
      cursor: pointer;
      width: 239px;
      height: 336px;
      background: #efefef;
      transition: width 0.3s, height 0.3s;

      &:hover {
        width: 400px;
        height: 621px;
      }
    }
  }

  .left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(to left, rgba(256, 256, 256, 0.4), rgba(256, 256, 256, 1));
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(to left, rgba(256, 256, 256, 1), rgba(256, 256, 256, 0.4));
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
* {
  margin: 0;
  padding: 0;
}
#wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99999;
}
ul {
  list-style: none;
}
li img {
  height: 100%;
  width: 100%;
}
.prev,
.next {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  top: 50%;
  margin-top: -56px;
  overflow: hidden;
  text-decoration: none;
  background-color: aqua;
  z-index: 5;
  opacity: 1;
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
.picleft {
  width: 400;
  top: 20;
  left: 50;
  opacity: 0.2;
  z-index: 2;
}
.piccenter {
  width: 800;
  top: 100;
  left: 200;
  opacity: 1;
  z-index: 4;
}
.picright {
  width: 400;
  top: 20;
  left: 750;
  opacity: 0.2;
  z-index: 2;
}
.modcon{
  width: 100%;
  height: 100%;
}
.modcon li{
  display: none;
}
.modcon li:nth-of-type(4){
  position: fixed;
  width: 160px;
  height: 210px;
  
  left: 160px;
  z-index: 0;
  cursor: default;
  display: block;
  transition: 1s;
  top: 50%;
    transform: translateY(-50%);
}
.modcon li:nth-of-type(5){
  position: fixed;
  width: 260px;
  height: 310px;
 
  left: 360px;
  z-index: 1;
  cursor: default;
  display: block;
  transition: 1s;
  top: 50%;
    transform: translateY(-50%);
}
.modcon li:nth-of-type(1){
  position: fixed;
  width: 560px;
  height: 750px;
 
  left: 640px;
  z-index: 2;
  cursor: default;
  display: block;
  transition: 1s;
  top: 50%;
  transform: translateY(-50%);
}
.modcon li:nth-of-type(2){
  position: fixed;
  width: 260px;
  height: 310px;
  
  right: 360px;
  z-index: 1;
  cursor: default;
  display: block;
  transition: 1s;
  top: 50%;
    transform: translateY(-50%);
}
.modcon li:nth-of-type(3){
  position: fixed;
  width: 160px;
  height: 210px;
  
  right: 160px;
  z-index: 0;
  cursor: default;
  display: block;
  transition: 1s;
  top: 50%;
    transform: translateY(-50%);
}
#arrLeft{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 55px;
  background: url("~@/assets/images/btn_next_prev.png?v=202109171826") 0 0 no-repeat;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 100;
  z-index: 9042;

}
#arrRight{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 55px;
  background: url("~@/assets/images/btn_next_prev.png?v=202109171826") 0 -81px no-repeat;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 100;
  z-index: 9042;

}
</style>