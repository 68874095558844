<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">JOB OFFERS</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">招聘信息</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="con">
          <div class="head">
            <div>职位</div>
            <div>城市</div>
            <div>发布日期</div>
            <div>详情</div>
          </div>
          <div class="position" v-for="(item, index) in pageData">
            <div class="poshead">
              <div>{{item.job}}</div>
              <div>{{item.city}}</div>
              <div>{{item.publish_time}}</div>
              <div style="cursor:pointer;" @click="godelete(index, item)">查看更多</div>
              <!-- <div style="cursor:pointer;" @click="godelete(index)">查看更多<img src="@/assets/images/mor.png"></div> -->
            </div>
            <div class="details" v-html="item.duty_content" v-if="navindex.includes(index)">

            </div>
            <div class="details" v-html="item.require_content" v-if="navindex.includes(index)">

            </div>
          </div>
        </div>
        
      </div>
      
    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

import { getRecruit } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      navindex: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getRecruit();
      console.log(res)
      this_.pageData = res.data;
    },
    dowload(e){
      let this_ = this
      window.open(this_.backurl+e.pdf_file, '_blank')
    },
    godelete(index, e){
      if(e.require_content){
        if(this.navindex.includes(index)){
          return this.navindex.splice(this.navindex.indexOf(index), 1);
        
        }else{
          this.navindex.push(index)
        }
      }

      if(e.more_url !=='' && e.more_url !== undefined && e.more_url !== null) {
        return window.open(e.more_url, '_blank')
      }
      
    }
  },
};
</script>
<style scoped>
p{
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #E3E3E3;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.list{
  display: flex;
  
}

.list{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left{
  display: flex;
  align-items: center;
}
.list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list-right{
  display: flex;
  align-items: center;
}
.list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.list{
  cursor:pointer;
}

.con{
  width: 100%;
  
  font-size: 20px;
    
  color: #FFFFFF;
}
.head{
  display: flex;
  justify-content: space-around;
  background: #0864C6;
  padding: 20px;
}
.con .head div{
  width: 25%;
  text-align: center;
}
.poshead div{
  width: 25%;
  text-align: center;
}
.position{

}
.poshead{
  display: flex;
  justify-content: space-around;
  padding: 20px;
  color: #0864C6;
  background: #EFEFEF;
}
.poshead img{
  width: 20px;
  margin: 0 5px;
  cursor:pointer;
}
.pos {
  width: 1100px;
  padding: 45px 45px 45px 136px;
  background: #0864C6;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 45px;
  display: flex;

  align-items: center;
}
.details{
  padding: 20px;
}
.demo {
  text-align: justify;
}
</style>