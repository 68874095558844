<template>
  <Layout>
    <div class="demo">
      <div class="box" style="padding-bottom:0;">
        <div class="title-box">
          <div class="title">
            
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">{{pageData.title}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="content" style="white-space: pre-wrap;" v-html="pageData.content"></div>
      </div>
    </div>

    
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getDetail } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let id = this_.$route.query.nid
      console.log()
      let res = await getDetail({nid:id, type:'system'});
      console.log(res)
      this_.pageData = res.data;
    },
    godetails(e){
      this.$router.push({path:'/NewDetails',query: {nid:e.id}})
    }
  },
};
</script>
<style scoped>
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}

.row-box{
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 7px 7px 13px 1px rgba(0,0,0,0.1);
  border-radius: 0px 0px 0px 0px;
}
.p1{
  padding: 0;
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: right;
  
  font-size: 23px;
  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4D4D4D;
  
}
.p2{
  
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: right;

  font-size: 14px;
  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4D4D4D;
}
.p3{
  padding: 0;
  padding: 0px 0px 0px 20px;
  margin: 20px;
  font-size: 13px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  line-height: 24px;
}

.p4{
  border-left: 1px solid #0864C6;
}
.row-box:hover p{
  color: #0864C6;
}

.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.demo {
  text-align: justify;
}
</style>