<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">PERFORMANCE REPORT</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">业绩简报</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="about-video" v-if="pageData">
            <video width="100%" height="100%" controls>
              <source
                :src="pageData"
                type="video/mp4"
              />
              <source
              :src="pageData"
                type="video/ogg"
              />
              <source
              :src="pageData"
                type="video/webm"
              />
              <object
                :data="pageData"
                width="100%"
                height="100%"
              >
                <embed
                  :src="pageData"
                  width="100%"
                  height="100%"
                />
              </object>
            </video>
          </div>
        <!-- <div class="" v-for="(item, index) in pageData" :key="item.id">
          <div class="list" @click="dowload(item)">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">{{item.title}}</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right">
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p3">{{item.download_num}}</p>
              </div>
            </div>
          </div>

        </div> -->
      </div>
      
    </div>

    
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getAchieve, getDownloadNumber, getInvestorAchieve } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: '',
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getInvestorAchieve();
      console.log(res)
      this_.pageData = res.data;
    },
    async dowload(e){
      let this_ = this
      let res = await getDownloadNumber({
        cd: e.id,
        cate: 1
      })
      window.open(this_.backurl+e.pdf_file, '_blank')
    }
  },
};
</script>
<style scoped>
p{
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}
.about-video{
  padding: 20px;
    padding-top: 0;
}
.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #E3E3E3;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.list{
  display: flex;
  
}

.list{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left{
  display: flex;
  align-items: center;
}
.list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list-right{
  display: flex;
  align-items: center;
}
.list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.list{
  cursor:pointer;
}
.list:hover .list-left-con .p1{
    color: #0864C6;
  }
  .demo {
  text-align: justify;
}
</style>