import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo' 


//首页

//了解金禄
/*公司简介
发展历程
企业文化
资质荣誉
联系方式*/
import JinluUnderstandProfile from '../views/JinluUnderstandProfile' //
import JinluUnderstandHistory from '../views/JinluUnderstandHistory' //
import JinluUnderstandCulture from '../views/JinluUnderstandCulture' //
import JinluUnderstandHonor from '../views/JinluUnderstandHonor' //
import JinluUnderstandContact from '../views/JinluUnderstandContact' //
// 产品技术
// 产品介绍
// 制程能力
// 专利授权
// 设备情况
import ProductIntroduce from '../views/ProductIntroduce' 
import ProductAbility from '../views/ProductAbility'//
import ProductPatent from '../views/ProductPatent' //
import ProductSituation from '../views/ProductSituation' //

//子公司
// 湖北金禄
// 香港凯美诺
import SubsidiaryHuBei from '../views/SubsidiaryHuBei' //
import SubsidiaryHongKong from '../views/SubsidiaryHongKong' //
import SubsidiaryShenZhen from '../views/SubsidiaryShenZhen' //


//社会责任
// 环境保护
// 安全生产
// 节能降耗
// 员工关怀
// 慈善公益
// 信息公示
import ResponsibilityEnvironment from '../views/ResponsibilityEnvironment' //
import ResponsibilityProduction from '../views/ResponsibilityProduction' //
import ResponsibilityEnergy from '../views/ResponsibilityEnergy' 

import ResponsibilityCharitable from '../views/ResponsibilityCharitable' 
//import ResponsibilityEnvironment from '../views/ResponsibilityEnvironment' //无原型无UI
import ResponsibilityCare from '../views/ResponsibilityCare' 
import ResponsibilityPublicity from '../views/ResponsibilityPublicity' //

//新闻资讯
// 新闻资讯
// 新闻详情
import NewList from '../views/NewList' //
import NewDetails from '../views/NewDetails' //

//人力资源
// 人才理念
// 福利待遇
// 晋升发展
// 招聘信息
import ManpowerIdea from '../views/ManpowerIdea' //
import ManpowerTreatment from '../views/ManpowerTreatment' //
import ManpowerDevelopment from '../views/ManpowerDevelopment' //
import ManpowerRecruitment from '../views/ManpowerRecruitment' // 无原型无UI


//投资者关系
// 业绩报告
// 股市行情
// 互动平台
// 定期报告
// 临时报告
// 公司治理
// 内控制度
// 股东回报
// 投资者教育
import InvestorPerformancereport from '../views/InvestorPerformancereport'//
import InvestorQuotation from '../views/InvestorQuotation' // 无页面
import InvestorInteraction from '../views/InvestorInteraction' //无UI
import InvestorPeriodicreport from '../views/InvestorPeriodicreport' //
import InvestorInterimreport from '../views/InvestorInterimreport' //
import InvestorGovernance from '../views/InvestorGovernance' //
import InvestorSystem from '../views/InvestorSystem' 

import InvestorSystemDetails from '../views/InvestorSystemDetails' 
import InvestorReturn from '../views/InvestorReturn' 
import InvestorEducation from '../views/InvestorEducation' 
import InvestorIR from '../views/InvestorIR' 
import InvestorNewmedia from '../views/InvestorNewmedia'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Demo',
    component: Demo,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 首页',
    }
  },
  {
    path: '/JinluUnderstandProfile',
    name: 'JinluUnderstandProfile',
    component: JinluUnderstandProfile,
    meta: {
      
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 公司简介',
      
    },
  },
  {
    path: '/JinluUnderstandHistory',
    name: 'JinluUnderstandHistory',
    component: JinluUnderstandHistory,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 发展历程',
    },
  },
  {
    path: '/JinluUnderstandCulture',
    name: 'JinluUnderstandCulture',
    component: JinluUnderstandCulture,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 企业文化',
    },
  },
  {
    path: '/JinluUnderstandHonor',
    name: 'JinluUnderstandHonor',
    component: JinluUnderstandHonor,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 荣誉资质',
    },
  },
  {
    path: '/JinluUnderstandContact',
    name: 'JinluUnderstandContact',
    component: JinluUnderstandContact,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 联系方式',
    },
  },
  {
    path: '/ProductIntroduce',
    name: 'ProductIntroduce',
    component: ProductIntroduce,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 产品介绍',
    },
  },
  {
    path: '/ProductAbility',
    name: 'ProductAbility',
    component: ProductAbility,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 制程能力',
    },
  },
  {
    path: '/ProductPatent',
    name: 'ProductPatent',
    component: ProductPatent,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 专利授权',
    },
  },
  {
    path: '/ProductSituation',
    name: 'ProductSituation',
    component: ProductSituation,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 设备情况',
    },
  },
  {
    path: '/SubsidiaryHuBei',
    name: 'SubsidiaryHuBei',
    component: SubsidiaryHuBei,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 湖北金禄',
    },
  },
  {
    path: '/SubsidiaryHongKong',
    name: 'SubsidiaryHongKong',
    component: SubsidiaryHongKong,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 香港凯美诺',
    },
  },
  {
    path: '/SubsidiaryShenZhen',
    name: 'SubsidiaryShenZhen',
    component: SubsidiaryShenZhen,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 深圳凯美诺',
    },
  },
  {
    path: '/ResponsibilityEnvironment',
    name: 'ResponsibilityEnvironment',
    component: ResponsibilityEnvironment,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 环境保护',
    },
  },
  {
    path: '/ResponsibilityProduction',
    name: 'ResponsibilityProduction',
    component: ResponsibilityProduction,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 安全生产',
    },
  },
  {
    path: '/ResponsibilityPublicity',
    name: 'ResponsibilityPublicity',
    component: ResponsibilityPublicity,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 信息公示',
    },
  },
  {
    path: '/ResponsibilityCharitable',
    name: 'ResponsibilityCharitable',
    component: ResponsibilityCharitable,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 慈善公益',
    },
  },
  {
    path: '/ResponsibilityCare',
    name: 'ResponsibilityCare',
    component: ResponsibilityCare,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 员工关怀',
    },
  },
//   ResponsibilityCharitable
// ResponsibilityEnergy
  {
    path: '/ResponsibilityEnergy',
    name: 'ResponsibilityEnergy',
    component: ResponsibilityEnergy,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 节能降耗',
    },
  },
  {
    path: '/NewList',
    name: 'NewList',
    component: NewList,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 新闻资讯',
    },
  },
  {
    path: '/NewDetails',
    name: 'NewDetails',
    component: NewDetails,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 新闻详情',
    },
  },
  {
    path: '/ManpowerIdea',
    name: 'ManpowerIdea',
    component: ManpowerIdea,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 人才理念',
    },
  },
  {
    path: '/ManpowerTreatment',
    name: 'ManpowerTreatment',
    component: ManpowerTreatment,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 福利待遇',
    },
  },
  {
    path: '/ManpowerDevelopment',
    name: 'ManpowerDevelopment',
    component: ManpowerDevelopment,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 晋升发展'
    },
  },
  {
    path: '/ManpowerRecruitment',
    name: 'ManpowerRecruitment',
    component: ManpowerRecruitment,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 招聘信息',
    },
  },
  // {
  //   path: '/ManpowerRecruitment',
  //   name: 'ManpowerRecruitment',
  //   component: ManpowerRecruitment,
  //   meta: {
  //     title: 'Doob || Doob Business and Consulting Bootstrap5 Template',
  //   },
  // },
  {
    path: '/InvestorPerformancereport',
    name: 'InvestorPerformancereport',
    component: InvestorPerformancereport,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 业绩简报',
    },
  },
  
  {
    path: '/InvestorQuotation',
    name: 'InvestorQuotation',
    component: InvestorQuotation,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 股市行情',
    },
  },
  {
    path: '/InvestorInteraction',
    name: 'InvestorInteraction',
    component: InvestorInteraction,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: 'Doob || Doob Business and Consulting Bootstrap5 Template',
    },
  },
  {
    path: '/InvestorPeriodicreport',
    name: 'InvestorPeriodicreport',
    component: InvestorPeriodicreport,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 定期报告',
    },
  },
  {
    path: '/InvestorInterimreport',
    name: 'InvestorInterimreport',
    component: InvestorInterimreport,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 临时报告',
    },
  },
  {
    path: '/InvestorGovernance',
    name: 'InvestorGovernance',
    component: InvestorGovernance,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 公司治理',
    },
  },
  {
    path: '/InvestorSystem',
    name: 'InvestorSystem',
    component: InvestorSystem,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 内控制度',
    },
  },
  {
    path: '/InvestorSystemDetails',
    name: 'InvestorSystemDetails',
    component: InvestorSystemDetails,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 内控制度',
    },
  },
  {
    path: '/InvestorReturn',
    name: 'InvestorReturn',
    component: InvestorReturn,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 股东回报',
    },
  },
  {
    path: '/InvestorEducation',
    name: 'InvestorEducation',
    component: InvestorEducation,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 投资者教育',
    },
  },
  {
    path: '/InvestorIR',
    name: 'InvestorIR',
    component: InvestorIR,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || IR管理',
    },
  },
  {
    path: '/InvestorNewmedia',
    name: 'InvestorNewmedia',
    component: InvestorNewmedia,
    meta: {
      title: '金禄电子科技股份有限公司官网'
      // title: '金禄 || 新媒体平台',
    },
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0)
});

export default router
