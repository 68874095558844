<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">QUALIFICATION HONOR</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">资质荣誉</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <Coursel :pageData="pageData" imageTitle="license_image"></Coursel>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Coursel from '@/components/elements/carousel'
import { getLicense } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout, Coursel },
  data () {
    return {
      pageData: []
    };
  },
  created () {
    this.init();
  },
  methods: {
    async init () {
      let this_ = this;
      let res = await getLicense();

      this_.pageData = res.data;
    }
  },
};
</script>
<style scoped lang="scss">
.main-content {
  background: #ffffff;
}

.honor-row {
  display: flex;
  /* flex布局 */
  justify-content: flex-start;
  /* 左对齐 */
  flex-wrap: wrap;
  /* 换行 */
}

.honor-nav {
  /* 每个元素右间距设置为20px */
  /* 计算每个元素的宽度：20px是前三个元素的间距（margin-right）和，除以4即为每个元素的宽度 */
}

.honor-nav:nth-of-type(4n + 0) {}

.honor-nav .p1 {
  width: 313px;
  height: 20px;
  /* background: #EFEFEF;
  border-radius: 0px 0px 0px 0px; */

  background-image: url("~@/assets/images/jinluUnderstand/3-11.png");

  background-repeat: no-repeat;
  background-position: top center;
}

.honor-nav .p2 {
  width: 313px;
  height: 38px;
  background: linear-gradient(180deg, #000000 0%, #FFFFFF 100%);
  opacity: 0.2;
}

.honor-nav .p3 {
  width: 313px;
  font-size: 16px;
  font-family: SourceHanSansSC-Regular-GBpc-EUC-H-, SourceHanSansSC-Regular-GBpc-EUC-H;
  font-weight: normal;
  color: #333333;
  line-height: 24px;
  padding-right: 50px;
}

.honor-row {
  display: flex;
}

.montop {
  margin-bottom: 20px;
}

.p1 {
  margin: 10px 0;
  /* height: 6px; */
  /* background: #efefef; */
}

.p2 {
  font-size: 9px;
  font-family: SourceHanSansSC-Regular-GBpc-EUC-H-,
    SourceHanSansSC-Regular-GBpc-EUC-H;
  font-weight: normal;
  color: #333333;
  line-height: 14px;
}


.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}

.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;
}

.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing: 4px;
}

.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;

}

.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}

.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}

.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}

.box2 {
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo {
  text-align: justify;
}
</style>