<template>
    <Layout>
      <div class="demo">
        <div class="box">
          <div class="title-box">
            <div class="title">
              <div class="y-title">EMPLOYEE CARE</div>
              <div class="z-title">
                <span class="sp1"></span>
                <span class="sp2"></span>
                <span class="sp3">员工关怀</span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="box2"  >
           
          <div class="product-nav">
            <!-- <div class="nav focus">{{item.name}}</div> -->
            <div class="nav" :class="{focus: navIndex == index} "  v-for="(item, index) in pageData" :key="'itme1'+index" @click="godis(index)">
              {{item.title}}
            </div>
          </div>
          <div class="introduce">
            <div 
            
            class="introduce-nav"
            v-for="(item, index) in prductList" :key="'itme2'+index"> 
              <img :src="backurl2 + item.image" />
              <p class="kl" v-if="item.describe">{{item.describe}}</p>
            </div>
          </div>
          
        </div>
        <!-- <div class="box2">
          <img src="@/assets/images/index/73.png" />
        </div> -->
  
        

      </div>
  
  
  
  
    </Layout>
  </template>
  
  <script>
  import Layout from "../components/common/Layout";
  import { getStaff } from "@/api/api";
  export default {
    name: "Demo",
    components: { Layout },
    data() {
      return {
        pageData: {},
        backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
        backurl2: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com",
        prductList:[],
        prductList2:{},
        prductListNum:[],
        navIndex: 0,
      };
    },
    created() {
      
    },
    mounted(){
      this.init();
    },
    watch:{
  
    },
    methods: {
        godis(index){
            this.navIndex = index
            this.postpre(index)
        },
      async init() {
        let this_ = this;
        let res = await getStaff();
        console.log(res)
        this_.pageData = res.data;
        this_.postpre(0)
      },
      postpre(index){
        let this_ = this
        console.log(this_.pageData[index])
        // this_.prductList = this_.pageData[index].images.split(',')
        this_.prductList = this_.pageData[index].image_data
        let arr = []
      },
      navclick(){
  
      },
      onleft(index,index2){
        let this_ = this
        console.log('index',index)
        console.log(this_.prductListNum[index])
        console.log(this_.prductList['list'+ index])
        if(Math.ceil(this_.prductList2['list'+ index].length) / 6 < this_.prductListNum[index]){
          this_.prductListNum[index] = this_.prductListNum[index]--
          
          this_.$set(this_.prductList, "list" + index, JSON.parse(JSON.stringify(this_.prductList2['list'+ index].slice((this_.prductListNum[index]-1)*6,this_.prductListNum[index]*6))))
  
          
        }
      },
      onright(index,index2){
        let this_ = this
        if(Math.ceil(this_.prductList2['list'+ index].length/6) < this_.prductListNum[index]){
          this_.prductListNum[index] = this_.prductListNum[index]++
          this_.$set(this_.prductList, "list" + index, JSON.parse(JSON.stringify(this_.prductList2['list'+ index].slice((this_.prductListNum[index]-1)*6,this_.prductListNum[index]*6))))
          console.log(this_.prductList['list'+ index])
        }
        //this_.prductList["list" + index] = JSON.parse(JSON.stringify(res.data.slice(0,6)))
      }
    },
  };
  </script>
  <style scoped>
  .motop{
    margin: 50px 0;
  }
  .main-content {
    background: #ffffff;
  }
  .bg {
    background: #ffffff;
    padding: 70px;
  }
  
  .product-nav{
    width: 220px;
    text-align: center;
  }
  .nav {
    cursor:pointer;
    display: flex;
      justify-content: center;
    padding: 20px 0px;
    background: #f8f8f8;
    font-size: 20px;
    font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
    font-weight: normal;
    color: #0864c6;
    font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .nav.focus {
    font-size: 25px;
    font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
    font-weight: normal;
    color: #ffffff;
    background: #0864c6;
  }
  .p1{
    text-align: center;
    font-size: 20px;
    font-family: SourceHanSansSC-Regular-GBpc-EUC-H-, SourceHanSansSC-Regular-GBpc-EUC-H;
    font-weight: normal;
    color: #0864C6;
  }
  .listnav{
    width: 990px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left, .right{
  
  }
  .left img, .right img{
    width: 21px;
    height: 21px;
  }
  .rd{
    width: 13px;
    height: 13px;
    background: #EFEFEF;
    border-radius: 50%;
    margin: 0 5px;
  }
  
  .demo {
    /* padding: 85px 0; */
    padding: 0 200px;
  }
  .box {
    padding: 68px 0;
    padding-left: 136px;
    border-left: 2px solid #0864c6;
  }
  
  .demo .title-box {
    display: flex;
  
  }
  .demo .title {
    
  }
  .demo .y-title {
    font-size: 60px;
    font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
    font-weight: normal;
    color: #efefef;
    letter-spacing:  4px;
  }
  .demo .z-title {
    display: flex;
    align-items: center;
    margin: 9px 0 ;
  
  }
  .demo .z-title .sp1 {
    width: 60px;
    height: 4px;
    background: #0864c6;
    border-radius: 0px 2px 2px 0px;
  }
  .demo .z-title .sp2 {
    width: 4px;
    height: 4px;
    background: #0864c6;
    margin: 0px 5px;
    border-radius: 2px 2px 2px 2px;
  }
  .demo .z-title .sp3 {
    margin-left: 40px;
    height: 40px;
    font-size: 30px;
    font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
      SourceHanSansSC-Normal-GBpc-EUC-H;
    font-weight: normal;
    color: #0864c6;
    line-height: 45px;
  }
  .box2{
    display: flex;
    padding-left: 136px;
    border-left: 2px solid #0864c6;
    padding-bottom: 100px;
  }
  .box3{
    border-left: 2px solid #0864c6;
    padding-bottom: 50px;
  }
  .introduce-nav{
    margin-left: 20px;
    margin-bottom: 20px;
    /* width: 350px; */
    width: calc(50% - 20px);
    height: 320px;
    background: #F8F8F8;
    border-radius: 0px 0px 0px 0px;
    position: relative;
    display: inline-block;
  }
  .introduce-nav img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-container img {
    display: block;
    max-width: 100%;
  }
  .introduce-nav .kl{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
   
    color: #fff;
    font-size: 18px;
    line-height: 1.2;
    padding: 20px;
    padding-bottom: 30px;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-size: 20px;
    font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
    font-weight: normal;
    color: #ffffff;
    background: #0864c6;
  }
  .introduce-nav:hover .kl {
    opacity: 1;
}
  .introduce{
    flex: 1;
    display: flex;
    flex-wrap:wrap;
  }
  .wu{
    font-size: 60px;
      font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
      font-weight: normal;
      color: #efefef;
      letter-spacing: 4px;
      margin: 0 auto;
      line-height: 280px;
  }
  
  .introduce-nav:hover{
    /* transform: scale(1.1); */
    cursor:pointer;
  }
  p{
    margin-bottom: 0;
  }
  .demo {
  text-align: justify;
}
  </style>