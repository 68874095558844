import { render, staticRenderFns } from "./ResponsibilityEnvironment.vue?vue&type=template&id=398991f7&scoped=true&"
import script from "./ResponsibilityEnvironment.vue?vue&type=script&lang=js&"
export * from "./ResponsibilityEnvironment.vue?vue&type=script&lang=js&"
import style0 from "./ResponsibilityEnvironment.vue?vue&type=style&index=0&id=398991f7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "398991f7",
  null
  
)

export default component.exports