<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">SUBSIDIARY</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">{{pageData.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box" style="padding-top: 0;">
        <div class="content" style="white-space: pre-wrap;" v-html="pageData.content"></div>
      </div>
      <div class="box" style="padding-top: 0;">
        <div>
          <div class="con-title">{{ lan == 'en' ? 'Contact' : '联系方式' }}</div>
          <!-- <p class="p1">
            <img src="@/assets/images/index/telephone2.png" />
            <span>{{pageData.tel}}</span>
          </p>
          <p class="p1 p2">
            <img src="@/assets/images/index/wz2.png" />
            <span>{{pageData.address}}</span>
          </p> -->
          <p class="p1" v-html="pageData.contact_content">
            
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="container bg bg-pos">
      <div class="row">
        <div class="y-title">SUBSIDIARY</div>
        <div class="z-title">
          <span class="sp1"></span>
          <span class="sp2"></span>
          <span class="sp3">{{pageData.name}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-6 col-sm-6 col-12" v-html="pageData.content">
          
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-6 col-sm-6 col-12">
          <div class="con-title">联系方式</div>
          <p class="p1">
            <img src="@/assets/images/index/wz.png" />
            <span>{{pageData.tel}}</span>
          </p>
          <p class="p1">
            <img src="@/assets/images/index/wz.png" />
            <span>{{pageData.address}}</span>
          </p>
        </div>
      </div>
    </div> -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getCompany } from "@/api/api";

export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      lan: sessionStorage.getItem('lang')
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      console.log(1)
      let res = await getCompany({cd: 1})
      this_.pageData = res.data
    },
  },
};
</script>
<style scoped>
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}

.con-title {
  margin-bottom: 30px;
  font-size: 30px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-, SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  line-height: 45px;
}
.p1 {
  margin: 10px 0;
  
  justify-content: flex-start;
  align-items: center;
}
.p1 /deep/ b{
  font-size: 24px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
  line-height: 33px;
}
.p1 /deep/ p{
  font-size: 24px;
font-family: Bahnschrift-, Bahnschrift;
font-weight: normal;
color: #212121;
line-height: 67px;
  
}
.p1 /deep/ ul, .p1 /deep/ li{
  list-style:none;
  font-size: 24px;
font-family: Bahnschrift-, Bahnschrift;
font-weight: normal;
color: #212121;
line-height: 67px;
}
.p1 img {
  display: block;
  width: 31px;
}
.p1 span {
  margin-left: 15px;
  font-size: 24px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
  line-height: 33px;
}
.p2 span{
  font-size: 24px;
font-family: Bahnschrift-, Bahnschrift;
font-weight: normal;
color: #212121;
line-height: 67px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.content{
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
  font-size: 16px;
  color:#212121;
}
/deep/ .content p{
  font-size: 16px;
    line-height: 42px;
    color: #212121;
    font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
}
.demo {
  text-align: justify;
}
</style>