import request from '@/api/request'

export function getIndex() {
  return request({
    url: '/api/abouts/index',
    method: 'get'
  })
}

//了解金禄-公司简历
export function getAboutJl() {
  return request({
    url: '/api/abouts/aboutJl',
    method: 'get'
  })
}

//了解金禄-发展历程
export function getDevelopment() {
  return request({
    url: '/api/abouts/development',
    method: 'get'
  })
}

//了解金禄-企业文化
export function getCulture() {
  return request({
    url: '/api/abouts/culture',
    method: 'get'
  })
}

//了解金禄-专利授权
export function getLicense() {
  return request({
    url: '/api/abouts/license',
    method: 'get'
  })
}

//了解金禄-联系方式
export function getContact() {
  return request({
    url: '/api/abouts/contact',
    method: 'get'
  })
}


//产品技术-制程能力
export function getAbility() {
  return request({
    url: '/api/pro/ability',
    method: 'get'
  })
}


//产品技术-专利授权
export function getPatent() {
  return request({
    url: '/api/pro/patent',
    method: 'get'
  })
}
//产品技术-设备情况
export function getDevice() {
  return request({
    url: '/api/pro/device',
    method: 'get'
  })
}

//子公司-
export function getCompany(data) {
  console.log(data)
  return request({
    url: '/api/sub_company/getCompany',
    method: 'get',
    params: data
  })
}

//社会责任-环境保护-
export function getProtect(data) {
  return request({
    url: '/api/duty/protect',
    method: 'get',
    params: data
  })
}
//社会责任-安全生产-
export function getSafety(data) {
  return request({
    url: '/api/duty/safety',
    method: 'get',
    params: data
  })
}

//新闻-list
export function getNews(data) {
  return request({
    url: '/api/news/getNews',
    method: 'get',
    params: data
  })
}
export function getDetail(data) {
  return request({
    url: '/api/news/detail',
    method: 'get',
    params: data
  })
}



export function getAchieve(data) {
  return request({
    url: '/api/investor/achieve',
    method: 'get',
    params: data
  })
}
export function getReg(data) {
  return request({
    url: '/api/investor/reg',
    method: 'get',
    params: data
  })
}
export function getTem(data) {
  return request({
    url: '/api/investor/tem',
    method: 'get',
    params: data
  })
}

export function getDerectors(data) {
  return request({
    url: '/api/investor/derectors',
    method: 'get',
    params: data
  })
}

export function getProductPategory(data) {
  return request({
    url: '/api/pro/product_category',
    method: 'get',
    params: data
  })
}
export function getPro(data) {
  return request({
    url: '/api/pro/getPro',
    method: 'post',
    data: data
  })
}
export function getSystem(data) {
  return request({
    url: '/api/investor/system',
    method: 'post',
    data: data
  })
}

export function getEducation(data) {
  return request({
    url: '/api/investor/education',
    method: 'post',
    data: data
  })
}
export function getNotice(data) {
  return request({
    url: '/api/duty/notice',
    method: 'post',
    data: data
  })
}
export function getCharitable(data) {
  return request({
    url: '/api/duty/charitable',
    method: 'post',
    data: data
  })
}
export function getStaff(data) {
  return request({
    url: '/api/duty/staff',
    method: 'post',
    data: data
  })
}

export function getRecruit(data) {
  return request({
    url: '/api/human/recruit',
    method: 'post',
    data: data
  })
}

export function getDutySave(data) {
  return request({
    url: '/api/duty/save',
    method: 'post',
    data: data
  })
}

// 计算下载量
export function getDownloadNumber(data) {
  return request({
    url: '/api/investor/downloadNumber',
    method: 'post',
    data: data
  })
}

// 人才理念
export function getHuman(data) {
  return request({
    url: '/api/human/ability',
    method: 'post',
    data: data
  })
}

export function getInvestorIrway(data) {
  return request({
    url: '/api/investor/irway',
    method: 'get',
    params: data
  })
}
export function getInvestorIractivity(data) {
  return request({
    url: '/api/investor/iractivity',
    method: 'get',
    params: data
  })
}

export function geinvestorgetNews(data) {
  return request({
    url: '/api/investor/getNews',
    method: 'post',
    data: data
  })
}

export function getDutyduty(data) {
  return request({
    url: '/api/duty/save',
    method: 'get',
    params: data
  })
}

export function geinvestorAeturn(data) {
  return request({
    url: '/api/investor/aeturn',
    method: 'post',
    data: data
  })
}

// 晋升发展
export function getHumanDevelopment(data) {
  return request({
    url: '/api/human/development',
    method: 'get',
    params: data
  })
}

// 业绩简报
export function getInvestorAchieve(data) {
  return request({
    url: '/api/investor/achieve',
    method: 'get',
    params: data
  })
}

// 公司治理
export function getInvestorOrgConfig(data) {
  return request({
    url: '/api/investor/orgConfig',
    method: 'get',
    params: data
  })
}


// 公司治理
export function getInvestorMedia(data) {
  return request({
    url: '/api/investor/media',
    method: 'get',
    params: data
  })
}

// 股东回报
export function getPrice(data) {
  return request({
    url: '/api/investor/getPrice',
    method: 'get',
    params: data
  })
}

// 福利待遇
export function getWelfare(data) {
  return request({
    url: '/api/human/welfare',
    method: 'get',
    params: data
  })
}