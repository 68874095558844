<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">STOCK QUOTES</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">股票行情</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="con">
          <!-- <div class="left">
            <div>中文全称：金禄电子科技股份有限公司</div>
            <div>证券简称: 金禄电子</div>
            <div>股票代码: 301282</div>
            
            <div>成立时间: 2006年</div>
            <div>上市时间: 2022年</div>
            <div>咨询邮箱: stock@camelotpcb.com</div>
            <div>咨询电话: 0763-3698068</div>
          </div> -->
          <div class="right">
            <div class="nav">
              <div :class="{a: navindex==0}" @click="navindex=0">分时</div>
              <div :class="{a: navindex==1}" @click="navindex=1">日K</div>
              <div :class="{a: navindex==2}" @click="navindex=2">周K</div>
              <div :class="{a: navindex==3}" @click="navindex=3">月K</div>
            </div>
            <div class="gppic">
              <img v-if="navindex==0" src="http://image.sinajs.cn/newchart/min/n/sz301282.gif" />
              <img v-if="navindex==1" src="http://image.sinajs.cn/newchart/daily/n/sz301282.gif" />
              <img v-if="navindex==2" src="http://image.sinajs.cn/newchart/weekly/n/sz301282.gif" />
              <img v-if="navindex==3" src="http://image.sinajs.cn/newchart/monthly/n/sz301282.gif" />
            </div>
          </div>
        </div>
        
      </div>
      <div class="box3">
        <div class="pos" v-html="printdata.content">
          <!-- <p>敬请登录深圳证券交易所查看更多信息</p>
          <p><a href="http://www.szse.cn/certificate/individual/index.html?code=301282">http://www.szse.cn/certificate/individual/index.html?code=301282</a></p> -->
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

import { getReg, getPrice } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      navindex: 0,
      printdata: {}
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getReg();
      let res2 = await getPrice()
      
      this_.printdata = res2
      console.log(res)
      this_.pageData = res.data;
    },
    dowload(e){
      let this_ = this
      window.open(this_.backurl+e.pdf_file, '_blank')
    }
  },
};
</script>
<style scoped>
p{
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #E3E3E3;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.list{
  display: flex;
  
}

.list{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left{
  display: flex;
  align-items: center;
}
.list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list-right{
  display: flex;
  align-items: center;
}
.list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.list{
  cursor:pointer;
}

.con{
  width: 100%;
  display: flex;
  font-size: 20px;
    
  color: #FFFFFF;
}
.right, .left{
  width: 100%;
}
.right{
  padding: 25px;
    padding-left: 50px;
}
.left{
  
  padding: 65px;
  background: #0864C6;
}
.left div{
  line-height: 50px;
}
.nav div{
  cursor:pointer;
  padding: 10px;margin: 0 5px;
  background: #EFEFEF;
}
.nav .a{
  color: #0864C6;
}
.gppic{
  margin-top: 20px;
}
img{    width: 100%;}
.pos {
  width: 1100px;
  padding: 45px 45px 45px 136px;
  background: #0864C6;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 45px;
  /* display: flex;

  align-items: center; */
}
.pos p{
    color: #FFFFFF;
    font-size: 25px;
    font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
      SourceHanSansSC-Normal-GBpc-EUC-H;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 45px;
   }
   .pos p a{
    color: #FFFFFF;
    font-size: 25px;
    font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
      SourceHanSansSC-Normal-GBpc-EUC-H;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 45px;
   }

   .demo {
  text-align: justify;
}
</style>
