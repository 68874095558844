<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">SHAREHOLDER RETURN PLANNING</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">股东回报规划</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="" >
          <!-- <div class="list" @click="dowload(item)"  v-for="(item, index) in pageData" :key="item.id">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">{{item.title}}</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right" >
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p3">{{item.download_num}}</p>
              </div>
            </div>
          </div> -->
          <!-- <div class="new-list" v-for="(item, index) in pageData" :key="item.id" @click="godetails(item)">
          <div class="left2" v-if="item.thumb_image">
            <img :src="backurl+item.thumb_image"/>
            
          </div>
          <div class="left" v-else>
            
            <p class="p1">
              {{item.status_text}}
            </p>
            <p class="p2">
              {{item.publish_time}}
            </p>
          </div>
          <div class="right2 p4" v-if="item.thumb_image">
            <p class="p2" style="font-size: 20px;font-weight: 700;">
              {{item.title}}
            </p>
            <p class="p3" style="color:#666;">{{item.publish_time}}</p>
          </div>
          <div class="right p4" v-else>
            <p class="p3">{{item.title}}</p>
          </div>
        </div> -->
        <div class="new-list" v-for="(item, index) in pageData.news" :key="item.id" @click="godetails(item)">
            <div class="left" >
              <p class="p1">
                {{item.publish_time.split("-")[1] + '-' + item.publish_time.split("-")[2]}}
              </p>
              <p class="p2">
                {{item.publish_time.split("-")[0]}}
              </p>
            </div>
            <div class="right p4">
              <p class="p3">{{item.title}}</p>
            </div>
          </div>
        </div>
        <!-- <div class="" v-for="(item, index) in pageData" :key="item.id">
          <div class="list">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">{{item.title}}</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right" @click="dowload(item)">
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p3">{{item.download_num}}</p>
              </div>
            </div>
          </div>

        </div> -->
      </div>

      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">PROFIT DISTRIBUTION SITUATION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">利润分配情况</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <img class="img2" :src="pageData.image" alt="">
      </div>
    </div>

<!-- 
    <div class="container bg bg-pos">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-sm-6 col-12">
          <div class="y-title">THE BRIEFING</div>
          <div class="z-title">
            <span class="sp1"></span>
            <span class="sp2"></span>
            <span class="sp3">股东回报规划</span>
          </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-6 col-sm-6 col-12">
          <div class="list">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">2022-10-01-2022年第三季度报告.pdf</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right">
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p1">1233</p>
              </div>
            </div>
          </div>

        </div>
        
      </div>

      <div class="row row2">
        <div class="col-lg-12 col-md-6 col-sm-6 col-12">
          <div class="y-title">THE BRIEFING</div>
          <div class="z-title">
            <span class="sp1"></span>
            <span class="sp2"></span>
            <span class="sp3">历年股东分配情况</span>
          </div>
        </div>
        
      </div>
      <div class="row ">
        <div class="col-lg-12 col-md-6 col-sm-6 col-12">
          分配表格
        </div>
      </div>
    </div> -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { geinvestorAeturn, getDownloadNumber } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await geinvestorAeturn();
      console.log(res)
      this_.pageData = res.data;
    },
    async dowload(e){
      let this_ = this
      let res = await getDownloadNumber({
        cd: e.id,
        cate: 8
      })
      e.download_num = e.download_num++
      window.open(this_.backurl+e.pdf_file, '_blank')
    },
    godetails(item){
      window.open(item.link, "_blank");
    }
  },
};
</script>
<style scoped>
p{
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #E3E3E3;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}

.list{
  display: flex;
  
}

.list{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left{
  display: flex;
  align-items: center;
}
.list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list-right{
  display: flex;
  align-items: center;
}
.list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.row2{
  margin-top: 67px;
}
.list{
  cursor:pointer;
}
.img2{
  width: 100%;
}
.list:hover .list-left-con .p1{
    color: #0864C6;
  }
  .new-list{
    width: 1382px;
    
    background: #FFFFFF;
    box-shadow: 10px 10px 20px 1px rgba(0,0,0,0.1);
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 30px;
    padding: 20px 0;
    display: flex;
  }
  .new-list .right{
    width: 81%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .new-list .left{
    width: 19%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .new-list .left2{
    width: 29%;
    padding: 10px;
  }
  .new-list .right2{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
  .new-list:hover p{
    color: #0864C6;
  }
  .new-list:hover{
    cursor:pointer;
  }
  .new-list .p1{
    padding: 0;
    padding: 0px 20px 0px 0px;
    margin: 0;
    text-align: center;
    font-size: 35px;


    font-family: DIN-Medium-, DIN-Medium;
    font-weight: normal;
    color: #4D4D4D;
  
}
.new-list .p2{
  
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: center;

  font-size: 21px;
  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #4D4D4D;
}
.new-list .p3{

  padding: 0;
  padding: 0px 0px 0px 20px;
  margin: 30px;
  font-size: 20px;

  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  
}

.new-list .right.p4{
  border-left: 1px solid #0864C6;
}
.demo {
  text-align: justify;
}
</style>