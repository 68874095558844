<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="culture-box" v-for="(item, index) in pageData.culture" :key="item.en_title">
          <div class="culture" v-if="(index+1) % 2 != 0">
            <div class="box7">
              <div class="box8">
                <div class="culture-left">
                  <div class="title-box">
                    <div class="title">
                      <div class="y-title">{{item.en_title}}</div>
                      <div class="z-title">
                        <span class="sp1"></span>
                        <span class="sp2"></span>
                        <span class="sp3">{{item.title}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="culture-left-con" style="white-space: pre-wrap;" v-html="item.describe">
                      
                  </div>
                </div>
                
                <div class="culture-right">
                  <img :src="backurl + item.image" alt="" />
                </div>
              </div>
              
            </div>
            
          </div>
          <div class="culture culture2" v-if="(index+1) % 2 == 0">
            <div class="box7">
              <div class="box8">
                <div class="culture-right">
                  <img :src="backurl + item.image" alt="" />
                </div>
                <div class="culture-left">
                  <div class="title-box" style="display: flex;justify-content: end;"">
                    <div class="title">
                      <div class="y-title" style="color: #E3E3E3;">{{item.en_title}}</div>
                      <div class="z-title">
                        <span class="sp3" style="margin-right: 40px;">{{item.title}}</span>
                        <span class="sp2"></span>
                        <span class="sp1"></span>
                      </div>
                    </div>
                  </div>
                  <div class="culture-left-con" style="white-space: pre-wrap;" v-html="item.describe">
                    
                  </div>
                </div>
              </div>
              
            </div>
            
          </div>
        </div>

      </div>
      <div class="box box3" style="background: #EFEFEF;">
        <div class="box6">
          <div class="y-title" style="color: #E3E3E3;">ENTERPRISE SPIRIT</div>
          <div class="z-title">
            <span class="sp1"></span>
            <span class="sp2"></span>
            <span class="sp3">企业精神</span>
          </div>
        </div>
        
      </div>
      <div class="box2 box4" style="background: #EFEFEF;">
        <div class="box6">
          <div class="fot-nav">
            <div class="nav" v-for="(item, index) in pageData.spirit" :key="'spirit' + index">
              <img :src="backurl + item.image" alt="">
              <p class="fot-nav-bot">{{item.title}}</p>
              <!-- <div class="title2">{{item.title}}</div> -->
            </div>
          </div>
        </div>
        
        
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getCulture } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getCulture();
      this_.pageData = res.data;
    },
  },
};
</script>
<style scoped>
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.matop{
  margin-top: 50px;
}
.z-title .sp3 {
  font-size: 17px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 25px;
}
.bg-pos {
  position: relative;
}
.pos {
  position: absolute;
  right: 80px;
  top: 0;
  width: 427px;
  height: 89px;
  background: #0864c6;
  border-radius: 0px 0px 0px 0px;
  font-size: 14px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.culture-b1 {
  display: flex;
  justify-content: space-between;
}
.culture-left-con {
  border-radius: 0px 0px 0px 0px;
  font-size: 24px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
  line-height: 58px;
}


.demo {
  /* padding: 85px 0; */
  /* padding: 0 200px; */
}
.box {
  /* padding: 68px 0;
  padding-left: 136px; */
  
  
}
.box3{
  padding: 0 200px;
  
 
 
}
.box4{
  padding: 0 200px;
  
  
  
}
.box6{
  padding-left: 136px;
  padding-bottom: 35px;
  padding-top: 70px;
  border-left: 2px solid #0864c6;
}
.box7{
  padding: 0 200px;
}
.box8{
  padding-bottom: 50px;
  padding-top: 70px;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  display: flex;
  width: 100%;
    justify-content: space-between;
}
.demo .title-box {
  display: flex;

}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
/* .box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
} */
.box3{
  
  
}
.demo .culture{
  width: 100%;
}
.culture-left .title-box{

}
.demo .culture{

}
.culture-box{
  
}
.culture-right {
  width: 500px;
  height: 250px;
}
.culture-right img {
  padding: 10px;
  width: 500px;
  height: 250px;
}
.culture-box{
 
}
.demo .culture2{
  background: #EFEFEF;

}
.demo  .culture2 .z-title{
  justify-content: end;
}
.culture2 .culture-left{

}
.demo  .culture2 .culture-left-con{
  text-align: right;
}
.fot-nav{
  display: flex;
  justify-content: space-between;
}

.fot-nav .nav {

  margin: 5px;
  width: 230px;
  border: 2px solid #ffffff;
  
}
.fot-nav .nav img{
  width: 220px;
  height: 290px;
}
.fot-nav .nav .fot-nav-bot{
  margin: 0;
  padding: 0;
  width: 220px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #EFEFEF;
  font-size: 20px;
  background: #0864c6;
}
.fot-nav .nav:hover{
  transform: scale(1.1);
}
.demo {
  text-align: justify;
}
</style>