import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: 'https://jl.szhknet.cn', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// request interceptor
service.interceptors.request.use(
  config => {
    console.log('config', config)
    if(sessionStorage.getItem('lang') == 'en'){
      if(config.method=='get'){
        if(!config.params){config.params={}}
        config.params.lang='en'
      }else{
        if(!config.data){config.data={}}
      config.data.lang='en'
      }
      
    }
    // window.location.
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // 401: Illegal token; 403: Other clients logged in; 403: Token expired;
      if (res.code === 401) {
        
      } else {

      }
      return response.data
      // return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
