<template>
  <Layout>
    <!-- <div class="m-right"></div> -->
    <div class="demo">
      <div class="box">
        <div class="title-box" id="tailk2">
          <div class="title">
            <div class="y-title">ABOUTUS</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3" v-if="pageData.abouts">{{ pageData.abouts.title }}</span>
            </div>
            <div class="about-des" v-html="content.section1_content">
              
            </div>
          </div>
         
          <!-- <div class="about-video" v-if="content.video_url">
            <video width="100%" height="100%" controls>
              <source
                :src="content.video_url"
                type="video/mp4"
              />
              <source
              :src="content.video_url"
                type="video/ogg"
              />
              <source
              :src="content.video_url"
                type="video/webm"
              />
              <object
                :data="content.video_url"
                width="100%"
                height="100%"
              >
                <embed
                  :src="content.video_url"
                  width="100%"
                  height="100%"
                />
              </object>
            </video>
          </div> -->
        </div>
        <div class="title-bottom" id="tailk" v-if="shouldShow">
          <div
            class="tail-nav"
            v-for="(item, index) in pageData.abouts.data"
            :key="'ab' + index"
          >
          
            <!-- <div class="tail-title number">{{ item.number }}</div> -->
            <div class="tail-title number">
              {{item.number.replace(/[0-9]/g, '')}}<VueCountUpV2 :endVal="item.number.replace(/[^\d]/g, ' ')" />
            </div>
            <div class="tail-con">{{ item.content }}</div>
          </div>
        </div>
        
      </div>
      
      <!-- 产品展示 -->
      <div class="box" >
        <div class="y-title">PRODUCTS</div>
        <div class="z-title" style="margin-bottom: 0;">
          <span class="sp1"></span>
          <span class="sp2"></span>
          <span class="sp3" >产品展示</span>
        </div>
      </div>
      <div class="box">
        <div class="product-box"> 
          <div class="left">
            <img
              @click="onleft"
              src="@/assets/images/index/left.png"
              alt=""
            />
          </div>
          <div class="middle">
            <div
              class="product-nav"
              v-for="(item, index) in product"
              :key="index + 'product'"
            >
              <p class="p1">
                <img class="img1" :src="backurl + item.finished_image" />
                <img class="img2" :src="backurl + item.thumb_image" />
                
              </p>
              <p class="p2">{{ item.name }}</p>
              
            </div>
          </div>
          <div class="right">
              <img
                @click="onright"
                src="@/assets/images/index/right.png"
                alt=""
              />
            </div>
        </div>
        
      </div>
      <!-- 新闻资讯 -->
      <div class="box">
        <div class="y-title">NEWS</div>
        <div class="z-title" style="margin-bottom: 0;">
          <span class="sp1"></span>
          <span class="sp2"></span>
          <span class="sp3">新闻资讯</span>
        </div>
      </div>
      <div class="box">
        <div class="new-con">
          <div class="new-box" style="padding-bottom: 50px;">
            <div class="new-pic">
              <img  src="@/assets/images/index/VCG211288687183.jpg" />
            </div>
            <div class="new" v-if="pageData.news">
              <div class="title">{{ pageData.news.company_news.name }}</div>
              <ul class="list">
                  <li
                    v-for="(item, index) in pageData.news.company_news.list"
                    :key="'new' + index"
                    @click="gonew(item)"
                    class="bule">
                    <div class="list-left ">
                      <p class="p1">
                        {{
                          item.publishtime.split("-")[1] +
                          "-" +
                          item.publishtime.split("-")[2]
                        }}
                      </p>
                      <p class="p2">{{ item.publishtime.split("-")[0] }}</p>
                    </div>
                    <div class="list-right">
                      <p class="p1">{{ item.title }}</p>
                    </div>
                  </li>
                </ul>
            </div>
            <div class="inf" v-if="pageData.news">
              <div class="title">{{ pageData.news.trade_news.name }}</div>
                <ul class="list">
                  <li
                    v-for="(item, index) in pageData.news.trade_news.list"
                    :key="'policy_news' + index"
                    @click="gonew(item)">
                    <div class="list-left bule"
                   >
                      <p class="p1">
                        {{
                          item.publishtime.split("-")[1] +
                          "-" +
                          item.publishtime.split("-")[2]
                        }}
                      </p>
                      <p class="p2">{{ item.publishtime.split("-")[0] }}</p>
                    </div>
                    <div class="list-right">
                      <p class="p1">{{ item.title }}</p>
                    </div>
                  </li>
                </ul>
            </div>
          </div>
          <div class="new-list" v-if="pageData.news">
            <div
              class="newtitle-list"
              v-for="(item, index) in pageData.news.trade_news.list"
              :key="'trade_news' + index"
            >
              <div class="newtitle" @click="gonew(item)">
                <div class="newtitle-left"></div>
                <div class="newtitle-right">
                  <p class="p1">{{ item.title }}</p>
                  <p class="p2">{{ item.publishtime }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>

    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getIndex, getAboutJl } from "@/api/api";
import VueCountUpV2 from 'vue-countup-v2'

export default {
  name: "Demo",
  // components: { Layout },
  components: {
    Layout,
    VueCountUpV2
  },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
      product: [],
      productps: {
        pise1: 0,
        pise2: 4,
      },
      video: '',
      content:{},
       // 定义滚动条的位置
       scrollPosition: 0,
      // 定义需要显示的滚动条的位置
      scrollThreshold: 0.46,
      shouldShow: false
    };
  },
  computed: {
    formattedNumber0() {
      return this.pageData.abouts.data[0].number.toString().padStart(4, '0');
    },
    formattedNumber1() {
      return this.pageData.abouts.data[1].number.toString().padStart(4, '0');
    },
    formattedNumber2() {
      return this.pageData.abouts.data[2].number.toString().padStart(4, '0');
    },
    
  },
  created() {
    this.init();
    
  },
  mounted: function () {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll)
    })
    
    // window.addEventListener('scroll', this.handleScroll);
  },
  // beforeDestroy: function () {
  //   window.removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
    handleScroll(e){

      

      let a = document.documentElement.scrollTop+document.getElementById('tailk2').clientHeight
      let b = document.getElementById('tailk2').offsetTop
      if(b<a){
        this.shouldShow = true
      }
      
     
      
     
    },
    async init() {
      let this_ = this;
      let res = await getIndex();
      let res2 = await getAboutJl()
      
      this_.content = res2.data
    
      console.log(res);
      console.log('res2', res2);
      this_.pageData = res;

      this_.product = res.product.slice(
        this_.productps.pise1,
        this_.productps.pise2
      );
    },
    gonew(e){
      console.log(e)
      window.location.href = e.link_url
      // this.$router.push({path:'/NewDetails',query: {nid:e.id}})
    },
    dowload(e) {
      let this_ = this;
      e.download_num = e.download_num++
      window.open(this_.backurl + e.pdf_file, "_blank");
    },
    onleft() {
      let this_ = this;
      this_.productps.pise1 = this_.productps.pise1 - 4;
      this_.productps.pise2 = this_.productps.pise2 - 4;
      if (this_.productps.pise1 <= 0) {
        this_.productps.pise2 = 4;
        this_.productps.pise1 = 0;
      }
      this_.product = this_.pageData.product.slice(
        this_.productps.pise1,
        this_.productps.pise2
      );
    },
    onright() {
      let this_ = this;
      this_.productps.pise1 = this_.productps.pise1 + 4;
      this_.productps.pise2 = this_.productps.pise2 + 4;
      console.log("this_.productps.pise2", this_.productps.pise2);
      if (this_.pageData.product.length <= this_.productps.pise2) {
        this_.productps.pise2 = this_.pageData.product.length;
        this_.productps.pise1 = this_.pageData.product.length - 4;
      }
      this_.product = this_.pageData.product.slice(
        this_.productps.pise1,
        this_.productps.pise2
      );
    },
  },
};
</script>
<style scoped>
.bg {
  padding: 61px 0;
}

.about-tail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.product-nav {
  justify-content: center;

  transition: transform 0.3s ease-in-out;

}
.product-nav:hover{
  transform: scale(1.1);
}

.new-box {
  padding: 0 70px;
}
.new-pic {
  width: 100%;

  border-radius: 29px 0px 0px 0px;
}
.new {
  margin-bottom: 20px;
}
ul,
li,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
p {
  margin: 0;
  padding: 0;
}

.new .title,
.inf .title {
  font-size: 17px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 30px;
}
.new .list {
}
.new .list li,
.inf .list li {
  display: flex;
  align-items: center;
  height: 68px;
}
.new .list .list-left,
.inf .list .list-left {
  width: 80px;
}
.new .list .list-left .p1,
.inf .list .list-left .p1 {
  font-size: 18px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #B0B0B0;

}
.new .list .list-left .p2,
.inf .list .list-left .p2 {
  font-size: 14px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #B0B0B0;

}
.new .list .list-right,
.inf .list .list-right {
  width: 475px;
  
  border-bottom: 1px solid #212121;
  background-image: url("~@/assets/images/index/right2.png");
  background-size: 10px 19px;
  background-repeat: no-repeat;
  background-position: center right;
}
.new .list .list-right p,
.inf .list .list-right p {
  padding: 10px 0;
  font-size: 18px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-, SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
  line-height: 29px;
}
.new .list .list-right:hover p{
  color: #0864C6;
}
.inf .list .list-right:hover p{
  color: #0864C6;
}
.new-list .newtitle-list .newtitle-right:hover {
  box-shadow: 0 0 60px rgba(0,0,0,.5);
}
.newtitle-list .newtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-left: 1px solid #000000; */
}
.newtitle-list {
  margin-bottom: 68px;
  
}
.newtitle-left {
  width: 15px;
  height: 15px;
  background: #0864C6;
  border-radius: 50%;
  margin-left: -8px;
  margin-right: 51px;
}
.newtitle-right {
  padding: 43px;
  width: 555px;
  height: 130px;
  background: #FFFFFF;
  box-shadow: 10px 10px 20px 1px rgba(0,0,0,0.1);
  border-radius: 0px 0px 0px 0px;
  box-shadow: 7px 7px 13px 1px rgba(0, 0, 0, 0.1);
}
.newtitle-right .p1 {
  
  height: 24px;
  font-size: 18px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  line-height: 28px;
}
.newtitle-right .p2 {
  height: 22px;
  font-size: 17px;
  font-family: CampaignGrotesk-, CampaignGrotesk;
  font-weight: normal;
  color: #B0B0B0;
  line-height: 30px;
  }
/* .row {
  margin-bottom: 30px;
} */

.demo {
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}
.demo .title-box {
  display: flex;
  
}
.demo .title {
  /* width: 430px;
  margin-right: 136px; */
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  line-height: 60px;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin-bottom: 69px;
}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.demo .about-des {
  font-size: 16px;
  line-height: 42px;
  color: #212121;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  text-align: justify;

}
.demo .about-video {
  width: 816px;
  height: 459px;
}
.demo .title-bottom {
  margin-top: 130px;
  /* margin-right: 0px; */
  display: flex;
  justify-content: space-evenly;
}
.demo .title-bottom .tail-nav {
  width: 401px;
  height: 158px;
  padding: 14px 0;
  text-align: center;
  background: #0864c6;
  color: #ffffff;
  animation: flyin 1s ease-in-out 0.5s forwards;
  /* box-shadow: 0 0 10px rgba(0,0,0,.3); */
  transition: box-shadow .3s ease;
  cursor:pointer;
}
.demo .title-bottom .tail-nav:hover {
  box-shadow: 0 0 60px rgba(0,0,0,.5);
}
.demo .title-bottom .tail-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #ffffff;
  line-height: 71px;
}
.demo .title-bottom .tail-con {
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 58px;
}
.demo .product-box{
  display: flex;
  align-items: center;
  margin-bottom: 106px;
}
.demo .left,
.demo  .right {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
.demo .left img,
.demo .right img {
  width: 40px;
  height: 40px;

}
.demo .middle{
  display: flex;
}
.demo .middle .product-nav{
  margin: 0 15px;
  width: 291px;
  
}
.product-nav p {
  margin: 0;
  padding: 0;
}
.product-nav .p1{
  height: 379px;
}
/*.product-nav .p1 img{
  height: 379px;
}*/
.product-nav .p2{
  text-indent: 1em;
  width: 291px;
  height: 93px;
  background: #0864C6;
  font-size: 22px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-, SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 70px;
  background: #0864C6;
  background-image: url("~@/assets/images/index/gg.png");
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
}
.product-nav .p3{
  padding: 0px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #0864C6;
  height: 45px;
}
.product-nav .p3 img{
  width:14px;
  height: 14px;
}
.product-nav .p3 span{
  width:234px;
  width: 234px;
  height: 0px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.demo .new-con{
  display: flex;
}
.demo .new-con .new-box{
  padding: 0 105px;
  width: 765px;
  border-right: 1px solid rgb(0, 0, 0);
}
.demo .new-con .new-box .title{
  padding: 30px 0;
  font-size: 25px;
  font-family: SourceHanSansSC-Bold-GBpc-EUC-H-, SourceHanSansSC-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  line-height: 45px;
  
}
.demo .new-con .new-box .new-pic{
  width: 555px;
  height: 239px;
  background: #FFFFFF;
  border-radius: 43px 0px 0px 0px;
}
.demo .new-con .new-box .new-pic img{
  width: 555px;
  height: 239px;
  background: #FFFFFF;
  border-radius: 43px 0px 0px 0px;
}
.demo .new-con .new-box .new{
 
}
.demo .new-con .new-box .inf{

}
.demo .new-list{

}
.m-right {
  position: absolute;
  left: 200px;
  width: 0px;
  height: 100%;
  opacity: 1;
  border: 1px solid #0864c6;
}
.new-con .list{
  cursor:pointer;
}
.new-con .newtitle-list{
  cursor:pointer;
}
@keyframes flyin {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.number:before {
  content: attr(data-value);
  position: relative;
  top: 0;
  transition: top 1s ease;
}
.number.active:before {
  top: -100%;
}

.new .list .bule:hover .list-right p{
  color: #0864C6;
}
.inf .list .bule:hover .list-right p{
  color: #0864C6;
}
.product-nav .p1{
  display: flex;
  justify-content: center;
  flex-direction:column;
}
.p1 .img1{
  display: flex;
  height: 50%;
}
.p1 .img2{
  height: 50%;
}
.p1:hover .img2{
  height: 50%;
}
.p1:hover .img1{
  display: flex;
  height: 50%;
}
</style>