<template>
  <Layout>
    <div class="demo">

      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">
              NEW MEDIA PLATFORM
            </div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">新媒体平台</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <!-- <div class="one"></div>
        <div class="two">
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-2"></div>
        </div>
        <div class="two">
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-1"></div>
          <div class="two-2"></div>
        </div> -->
        <div class="boo">
          <div class="imgbox1" v-if="pageData1[0]">
            <img :src="pageData1[0].thumb_images" >
            <p>{{pageData1[0].title}}</p>
          </div>
          <div class="imgbox2" v-if="pageData1[1]">
            <img :src="pageData1[1].thumb_images" >
            <p>{{pageData1[1].title}}</p>
          </div>
        </div>
        
      </div>
    </div>

  </Layout>
</template>
<style scoped>
.boo{
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.imgbox1 img{
  height: 250px;
  width: 250px;
}
.imgbox2 img{
  height: 250px;
  width: 250px;
}
.imgbox1 p{
  /* width: 250px; */
  color: #666666;
  font-size: 25px;
  text-align: center;
}
.imgbox2 p{
  /* width: 250px; */
  color: #666666;
  font-size: 25px;
  text-align: center;
}
</style>

<script>
import Layout from "../components/common/Layout";
import { getInvestorMedia } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData2: {},
      pageData1: [],
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res1 = await getInvestorMedia();
      this_.pageData1 = res1.data;
    },
    dowload(e){
      let this_ = this
      window.open(this_.backurl+e.pdf_file, '_blank')
    }
  },
};
</script>
<style scoped>
.dd{
  margin-bottom: 40px;
}
p{
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #E3E3E3;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}


.list{
  display: flex;
  
}
.arti{
  font-size: 20px;
  color:#000000;
  padding: 20px 30px 25px 0px;
}
.list{
  cursor:pointer;
}
.list{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left{
  display: flex;
  align-items: center;
}
.list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list-right{
  display: flex;
  align-items: center;
}
.list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.list:hover .list-left-con .p1{
    color: #0864C6;
  }
  .one {
  background: #0864c6;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  padding: 0 20px;
  height: 95px;
}
.two {
  display: flex;
  height: 95px;
  color: #0864c6;
  background: #e0e0e0;
}
.two-1 {
  padding: 20px 0;
  display: inline-block;
  text-align: center;
  height: 95px;
  line-height: 95px;
  width: 240px;
  border-top: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
}
.two-2 {
  padding: 20px 0;
  display: inline-block;
  text-align: center;
  height: 95px;
  line-height: 95px;
  width: 240px;
  border-top: 5px solid #ffffff;
  border-right: 5px solid #ffffff;

  flex: 1;
}
.two-3 {
}
.demo {
  text-align: justify;
}
</style>