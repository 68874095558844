<template>
  <Layout>
    <div class="demo">

      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">INVESTOR CONSULTATION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">投资者咨询方式</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2" style="padding-bottom:0;">
        <!-- <div class="arti" v-for="(item, index) in pageData1" :key="item.key">
          {{ item.key + ':' + item.value }}
        </div> -->
        <div class="arti" v-html="pageData1.way_content">
          <!-- {{ item.key + ':' + item.value }} -->
        </div>
      </div>
      
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">INVESTOR RELATIONS ACTIVITIES</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">投资者关系活动</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="" v-for="(item, index) in pageData2" :key="item.id">
          <div class="list" @click="dowload(item)">
            <div class="list-left">
              <img src="@/assets/images/investor/pdf.png" />
              
              <div class="list-left-con">
                <p class="p1">{{item.title}}</p>
                <p class="p2">3.58MB</p>
              </div>
            </div>

            <div class="list-right">
              <img src="@/assets/images/investor/download.png" />
              <div class="list-right-con">
                <p class="p3">{{item.download_num}}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      
    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getInvestorIrway, getInvestorIractivity } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData2: {},
      pageData1: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res1 = await getInvestorIrway();
      let res2 = await getInvestorIractivity();
      console.log(res1,res2)
      this_.pageData1 = res1.data;
      this_.pageData2 = res2.data;
    },
    dowload(e){
      let this_ = this
      window.open(e.link, '_blank')
    }
  },
};
</script>
<style scoped>
.dd{
  margin-bottom: 40px;
}
p{
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #E3E3E3;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}


.list{
  display: flex;
  
}
.arti{
  font-size: 20px;
  color:#000000;
  padding: 20px 30px 25px 0px;
}
.list{
  cursor:pointer;
}
.list{
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left{
  display: flex;
  align-items: center;
}
.list-left img{
  margin-right: 10px;
  width:56px;
  height: 56px;
}
.list-left-con .p1{
  font-size: 20px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 37px;
}
.list-left-con .p2{
  font-size: 15px;
font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
font-weight: normal;
color: #4D4D4D;
line-height: 28px;
}
.list-right{
  display: flex;
  align-items: center;
}
.list-right img{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3{
  font-size: 27px;
font-family: DIN-Medium-, DIN-Medium;
font-weight: normal;
color: #C9C9C9;
line-height: 50px;
}
.list:hover .list-left-con .p1{
    color: #0864C6;
  }
  .demo {
  text-align: justify;
}
</style>