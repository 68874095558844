<template>
  <Layout>
    <div class="demo">
      <div class="box" style="padding-bottom:0;">
        <div class="title-box">
          <div class="title">
            <div class="y-title">ENERGY CONSERVATION AND CONSUMPTION REDUCTION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">节能降耗</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="content" style="white-space: pre-wrap;" v-html="pageData"></div>
      </div>
    </div>
  </Layout>
</template>
  <script>
import Layout from "../components/common/Layout";
import { getDutySave, getDownloadNumber } from "@/api/api";
import { getDutyduty } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getDutyduty();
      console.log(res)
      this_.pageData = res.data;
    },
  },
};
</script>
  <style scoped>
p {
  margin: 0;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  
}
.demo .title {
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #e3e3e3;
  letter-spacing: 4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;
}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2 {
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3 {
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.content {
  color: #000000;
}

.list {
  display: flex;
}

.list {
  padding: 20px 30px 25px 0px;
  border-bottom: 2px dashed #666666;
  justify-content: space-between;
}
.list-left {
  display: flex;
  align-items: center;
}
.list-left img {
  margin-right: 10px;
  width: 56px;
  height: 56px;
}
.list-left-con .p1 {
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4d4d4d;
  line-height: 37px;
}
.list-left-con .p2 {
  font-size: 15px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-,
    SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4d4d4d;
  line-height: 28px;
}
.list-right {
  display: flex;
  align-items: center;
}
.list-right img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.p3 {
  font-size: 27px;
  font-family: DIN-Medium-, DIN-Medium;
  font-weight: normal;
  color: #c9c9c9;
  line-height: 50px;
}
.list {
  cursor: pointer;
}
.pos {
  width: 1100px;
  padding: 45px 45px 45px 136px;
  background: #0864C6;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 45px;
  /* display: flex;

  align-items: center; */
}
 .pos p{
  color: #FFFFFF;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 45px;
 }
 .pos p a{
  color: #FFFFFF;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 45px;
 }
.content /deep/ p span{
  font-size: 16px;
  font-family: SourceHanSansSC-Light-GBpc-EUC-H-, SourceHanSansSC-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
}
.demo {
  text-align: justify;
}
</style>