<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">CONTACT</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">金禄电子科技股份有限公司</span>
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <!-- <div class="con">
          <div class="con-left">
            <p class="title">{{ pageData[0].name }}</p>
            <p class="p1">
              <img src="@/assets/images/index/telephone2.png" />
              <span class="sp1 long-add">{{ pageData[0].address }}</span>
            </p>
            <p class="p1">
              <img src="@/assets/images/index/wz2.png" />
              <span>{{ jsonpa(pageData[0].tel_json) }}</span>
            </p>
            <p class="p1">
              <img src="@/assets/images/index/24gf-telephone2.png" />
              <span>{{ pageData[0].phone }}</span>
            </p>
            <p class="p1">
              <img src="@/assets/images/index/dyj.png" />
              <span>{{ pageData[0].fax }}</span>
            </p>
            <p class="p1">
              <img src="@/assets/images/index/yj.png" />
              <span>{{ pageData[0].email }}</span>
            </p>
          </div>
          <div class="con-right">
            <p class="title">{{ pageData[1].name }}</p>
            <p class="p1">
              <img src="@/assets/images/index/telephone2.png" />
              <span class="sp1">{{ pageData[1].address }}</span>
            </p>
            <p class="p1">
              <img src="@/assets/images/index/wz2.png" />
              <span>{{ jsonpa(pageData[1].tel_json) }}</span>
            </p>
          </div>
        </div> -->
        <div class="con">
          <div class="con-left" v-for="(item, index) in pageData" :key="index+'con'">
            <!-- <p class="title">{{ item.company }}</p> -->
            <p class="p1" v-html="item.content"></p>
            <!-- <p class="p1">
              <img src="@/assets/images/index/telephone2.png" />
              <span class="sp1 long-add">{{ pageData[0].address }}</span>
            </p> -->

          </div>
          <!-- <div class="con-right">
            <p class="title">{{ pageData[1].company }}</p>
            <p class="p1" v-html="pageData[1].content"></p>
           

          </div> -->
        </div>
      </div>

    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getContact } from "@/api/api";

export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getContact();
      this_.pageData = res.data;
      console.log('this_.pageData', this_.pageData)
    },
    jsonpa(jData) {
      let obj = JSON.parse(jData)
      let src = ""
      for (let key in obj) {
        console.log(obj[key]) // foo, bar
        if (src) {
          src = src + '   ' + obj[key]
        } else {
          src = src + obj[key]
        }

      }
      return src
    }
  },
};
</script>
<style lang="scss" scoped>
.main-content {
  background: #ffffff;
}

.row-con .title {
  font-size: 17px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-,
    SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 25px;
}

.row-con .p1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row-con .p1 img {
  display: block;
  width: 17px;
  height: 22px;
}

.row-con .p1 span {
  font-size: 14px;
  font-family: Bahnschrift-, Bahnschrift;
  font-weight: normal;
  color: #212121;
  line-height: 38px;
}
.row-con :deep .p1 p{
  font-size: 14px;
  font-family: Bahnschrift-, Bahnschrift;
  font-weight: normal;
  color: #212121;
  line-height: 38px;
}
.row-con .p1 img {
  display: block;
  width: 17px;
  height: 22px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}

.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}

.demo .title {}

.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing: 4px;
}

.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0;

}

.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}

.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}

.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}

.honor {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.honor .honor-nav {
  margin-bottom: 144px;
}

.box2 {
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 601px;
}

.con {
  display: flex;
  justify-content: space-between;
}

.con .title {
  font-size: 30px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-, SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  line-height: 45px;
}

.con-left {}

.con-right {}

.con .p1 img {

  width: 31px;

}

.con-left p,
.con-right p {}

.con-left p span,
.con-right p span {

  font-size: 24px;
  font-family: Bahnschrift-, Bahnschrift;
  font-weight: normal;
  color: #212121;
  line-height: 67px;
}

.con-left p .sp1,
.con-right p .sp1 {
  font-size: 24px;
  font-family: SourceHanSansSC-Medium-GBpc-EUC-H-, SourceHanSansSC-Medium-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
  line-height: 50px;
  &.long-add {
    width: 504px;
    line-height: 32px;
  }
}

.demo {
  background-image: url("~@/assets/images/4-03.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;

}

.con :deep .p1 p {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Bahnschrift-, Bahnschrift;
  font-weight: normal;
  color: #212121;
  line-height: 38px;
}

.con .p1 img {
  margin-right: 10px;
}
.demo {
  text-align: justify;
}
</style>