<template>
  <Layout>
    <div class="demo">
      <div class="box" style="padding-bottom:0;">
        <div class="title-box">
          <div class="title">
            <div class="y-title">ENVIRONMENTAL PROTECTION</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <span class="sp3">环境保护</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="content" style="white-space: pre-wrap;" v-html="pageData"></div>
      </div>
    </div>


  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getProtect } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getProtect();
      console.log(res)
      this_.pageData = res.data;
    },
  },
};
</script>
<style scoped>
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}

.bg-pos {
  position: relative;
}
.pos {
  position: absolute;
  right: 80px;
  top: 0;
  width: 427px;
  height: 89px;
  background: #0864c6;
  border-radius: 0px 0px 0px 0px;
  font-size: 14px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #ffffff;
  line-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {
  display: flex;

}
.demo .title {
  
}
.demo .y-title {
  font-size: 60px;
  font-family: Bahnschrift-Bold-, Bahnschrift-Bold;
  font-weight: normal;
  color: #efefef;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.content{
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
  font-size: 16px;
  color:#212121;
}
.content /deep/ p span{
  font-size: 16px;
  font-family: SourceHanSansSC-Light-GBpc-EUC-H-, SourceHanSansSC-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #212121;
}
.demo {
  text-align: justify;
}
</style>