<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title2">
            成长力<br/>年轻敢战，成长不断
          </div>
          <div class="z-title">
            <span class="sp1"></span>
            <span class="sp2"></span>
          </div>
        </div>
      </div>

      <div class="box3">
        <div class="pos">
          我们希望你不做螺丝钉，不徒劳重复，创造精彩未来！
        </div>
      </div>
      <div class="box">
        <img :src="humanData" />
      </div>
    </div>


  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

import { getHumanDevelopment } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      humanData: ""
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getHumanDevelopment();
      console.log(res)
      this_.humanData = res.data;
    },
  }
};
</script>
<style scoped>
p{
  padding: 0;
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.row2{
  margin-top: 67px;
}
.title-box{
  line-height: 37px;
  font-size: 20px;
  color: #4D4D4D;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
}
.row2{
  margin-top: 67px;
}
.title1{
  color: #0864C6;
  font-size: 30px;
  line-height: 45px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
}
.title2{
  font-size: 65px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-;
  font-weight: normal;
  color: #0864C6;
  line-height: 119px;
}
.title3{
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  line-height: 37px;
}
.row3 .con{
  display: flex;
  flex-wrap: wrap;
  padding-right: 330px;
}
.row3 .con div{
  width: 217px;
  height: 54px;
  margin-right: 10px;
}
.row3 .con div img{
  display: inline-block;
  width: 217px;
  height: 54px;
}
.row3 .con div:nth-child(odd){
  margin-bottom: 10px;
}
.row3 .con div:nth-child(even){

  margin-bottom: 10px;
}

.bg-pos {
  position: relative;
}
.pos {
  width: 1203px;
  padding: 45px 45px 45px 136px;
  background: #0864C6;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #efefef;
  line-height: 45px;
  display: flex;

  align-items: center;

  
}
.con1{
  font-size: 13px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  line-height: 34px;
}
img{
  width: 100%;
}
.demo {
  text-align: justify;
}
</style>