<template>
  <div id="app" class="block1">
    <router-view/>
  </div>
    
</template>

<script>

window.addEventListener('resize', () => setTimeout(function () {  //监测分辨率发生改变就对页面进行刷新，可以改变zoom的值
    location.reload();
}, 10));
export default {
    data() {
        return {
          a: null,
        }
    },
    props: {
        // a: {
        //     type: String,
        //     default: "2",
        // },
    },
    created() {
      function IsPhone() {
          //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
          var info = navigator.userAgent;
          //通过正则表达式的test方法判断是否包含“Mobile”字符串
          var isPhone = /mobile/i.test(info);
          //如果包含“Mobile”（是手机设备）则返回true
          return isPhone;
      }
      // if (IsPhone()) {   //手机设备发现会出现问题，所以在手机页面打卡时。就不使用这个方法
      // } else {
          console.log(document.body.clientWidth + 0.00);
          this.a = (document.body.clientWidth) / 1920
          document.getElementsByTagName("body")[0].style.setProperty("--a", this.a);
      // }
    },
}


  //document.body.style.zoom = document.body.clientWidth/1920


</script>

<style lang="scss">
:root {
    --a: 1;
}
 
.block1 {
    zoom: var(--a);
}

</style>
<style>
/* @font-face {
  font-family: "SourceHanSansSC-Light-GBpc-EUC-H-"; 
  src: url("/assets/fonts/SourceHanSansSC-Light.otf") format("otf");
  font-family: "AdobeHeitiStd-Regular-GBpc-EUC-H-"; 
  src: url("/assets/fonts/AdobeHeitiStd-Regular.otf") format("otf");
  font-family: "Bahnschrift-"; 
  src: url("/assets/fonts/bahnschrift_1.otf") format("otf");
  font-family: "CampaignGrotesk-"; 
  src: url("/assets/fonts/Campaign Regular.otf") format("otf");
  font-family: "SourceHanSansSC-Bold-GBpc-EUC-H-"; 
  src: url("/assets/fonts/SourceHanSansSC-Bold.otf") format("otf");
  font-family: "SourceHanSansSC-Medium-GBpc-EUC-H-"; 
  src: url("/assets/fonts/SourceHanSansSC-Medium.otf") format("otf");
  font-family: "SourceHanSansSC-Normal-GBpc-EUC-H-"; 
  src: url("/assets/fonts/SourceHanSansSC-Normal.otf") format("otf");
  font-family: "SourceHanSansSC-Regular-GBpc-EUC-H-"; 
  src: url("/assets/fonts/SourceHanSansSC-Regular.otf") format("otf");
  
} */
@font-face {
  font-family: "SourceHanSansSC-Light-GBpc-EUC-H-"; 
  src: url("assets/fonts/SourceHanSansSC-Light.otf") format("otf");
}
@font-face {
  font-family: "AdobeHeitiStd-Regular-GBpc-EUC-H-"; 
  src: url("assets/fonts/AdobeHeitiStd-Regular.otf") format("otf");
}
@font-face {
  font-family: "Bahnschrift-"; 
  src: url("assets/fonts/bahnschrift_1.ttf") format("ttf");
}
@font-face {
  font-family: "CampaignGrotesk-"; 
  src: url("assets/fonts/Campaign Regular.otf") format("otf");
}
@font-face {
  font-family: "SourceHanSansSC-Bold-GBpc-EUC-H-"; 
  src: url("assets/fonts/SourceHanSansSC-Bold.otf") format("otf");
}
@font-face {
  font-family: "SourceHanSansSC-Medium-GBpc-EUC-H-"; 
  src: url("assets/fonts/SourceHanSansSC-Medium.otf") format("otf");
}
@font-face {
  font-family: "SourceHanSansSC-Normal-GBpc-EUC-H-"; 
  src: url("assets/fonts/SourceHanSansSC-Normal.otf") format("otf");
}

@font-face {
  font-family: "SourceHanSansSC-Regular-GBpc-EUC-H-"; 
  src: url("assets/fonts/SourceHanSansSC-Regular.otf") format("otf");
}
</style>
