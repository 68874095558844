<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title">
            <div class="y-title">欢迎加入金禄π队！</div>
            <div class="z-title">
              <span class="sp1"></span>
              <span class="sp2"></span>
              <!-- <span class="sp3">新闻详情</span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="title-box">
          <p>你，金光闪闪。</p> 
          <p>你的未来如同π，无限不循环，潜能无极限，想象无边际。</p> 
          <p>在探索高新技术的路上，我们也能发现你的独特光芒。 </p>
          <p>
            因此，想要给你一个尽情发光的舞台——金禄。
          </p>
          
        </div>
      </div>

      <div class="box2">
        <div class="title1">人才理念</div>
        <div class="title2">德才兼备，以德为本，尚贤用能，绩效为先</div>
      </div>

      
      <div class="box2" v-for="(item, index) in pageData" :key="item">
        <div class="title1" v-if="index!=1">{{item.title}}</div>
        <div class="title3">{{item.describe}}</div>
        <div class="con">
          <div class="con-left" v-for="(item2, index2) in item.data" :key="item2">
            <img :src="backurl + item2.image" />
            <span class="tty">{{ item2.title }}</span>
          </div>
          <!-- <div class="con-right">
            <img src="@/assets/images/manpower/2.png" />
          </div> -->
        </div>
      </div>

      <!-- <div class="box2">
        <div class="title1">我们的生活</div>
        <div class="title3">生活在金禄，有着浓厚的家园文化。我们关心每一位员工的身体健康，定期安排体检；我们关心每一位员工是否吃饱穿暖，于是提供免费食堂和24小时热心宿管。</div>
          
        <div class="con">
          <div class="con">
            <div class="con-left">
              <img src="@/assets/images/manpower/3.png" />
            </div>
            <div class="con-right">
              <img src="@/assets/images/manpower/4.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="box2">
        <div class="title1">我们的活动</div>
        <div class="title3">多样的主题文化活动与个性化的俱乐部，打造缤纷多彩的文体活动，丰富员工业余生活。</div>
          
        <div class="con">
          <div class="con">
            <div class="con-left">
              <img src="@/assets/images/manpower/5.png" />
            </div>
            <div class="con-right">
              <img src="@/assets/images/manpower/6.png" />
            </div>
          </div>
        </div>
      </div> -->
      
    </div>

   
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

import { getHuman } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getHuman();
      console.log(res)
      this_.pageData = res.data;
    },
  }
};
</script>
<style scoped>
p{
  padding: 0;
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.title-box{
  line-height: 37px;
  font-size: 20px;
  color: #4D4D4D;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
}
.row2{
  margin-top: 67px;
}
.title1{
  color: #0864C6;
  font-size: 30px;
  line-height: 45px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
}
.title2{
  font-size: 65px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-;
  font-weight: normal;
  color: #0864C6;
  line-height: 119px;
}
.title3{
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  line-height: 37px;
}
.row3 .con{
  display: flex;
  flex-wrap: wrap;
}
.row3 .con div:nth-child(odd){
  padding-right: 5px;
  margin-bottom: 10px;
}
.row3 .con div:nth-child(even){
  padding-left: 5px;
  margin-bottom: 10px;
}

.con{
  margin-top: 15px;
  display: flex;
}
.con .con-left{

}
.con .con-left{
  
}
.con .con-left:hover{
  transform: scale(1.1);
}
.con .con-left .tty{

  margin-top: -50px;
  display: flex;
  justify-content: center;
  width: 180px;
  height: 50px;
  line-height: 50px;
  background-image: url("~@/assets/images/rcb.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left center;
  color: #FFFFFF;
    position: absolute;
}
.con .con-right{

}
.con img{
  width: 678px;
  height: 350px;
}
.con div:nth-child(odd){
  padding-right: 10px;
  margin-bottom: 10px;
}
.con div:nth-child(even){
  padding-left: 10px;
  margin-bottom: 10px;
}
.demo {
  text-align: justify;
}
</style>