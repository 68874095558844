<template>
  <Layout>
    <div class="demo">
      <div class="box">
        <div class="title-box">
          <div class="title2">
            吸引力<br/>福利多多,诚意满满
          </div>
          <div class="z-title">
            <span class="sp1"></span>
            <span class="sp2"></span>
          </div>
        </div>
      </div>

      <div class="box3">
        <div class="pos">
          我们希望给你创造更多更心动的待遇！
        </div>
      </div>

      <div class="box2">
        <div class="title1">
          {{ pageData.title }}
        </div>
        <div class="con1" v-html="pageData.content">

        </div>
        <!-- <div class="con1">
            1.入职即缴交五险一金。 
          </div>
          <div class="con1">
            2.一对一培带，参与专利项目，撰写论文，参与清远市人才项目评审。 
          </div>
          <div class="con1">
            3.入职后报考学历提升（专升本），领取毕业证后全额报销学费。 
          </div>
          <div class="con1">
            4.①包吃包住，由有热心的宿管员管理，提供衣物免费缝补服务。②通勤车免费接送上下班。 
          </div>
          <div class="con1">
            5.每年组织员工免费进行常规健康体检、职业卫生体检，厂内开设医务。 
          </div>
          <div class="con1">
            6.设置悦读会、健身房、台球、兵乓球等供员工免费试用，定期举办各种文体活动，如:跑团、羽毛球赛、篮球赛、大合唱、表彰会、动员会等。 
        
          </div>
          <div class="con1">
            7.设立工会，定期组织活动。还有互助会，不幸遇上重大疾病、天灾人祸可申请互助金。 
          </div>
          <div class="con1">
            8.设立党支部，有更多的入党机会向党组织靠拢。
          </div> -->
      </div>

      <div class="box2">
        <div class="con">
          <div class="">
            <img src="@/assets/images/manpower/f1.png" />
          </div>
          <div class="">
            <img src="@/assets/images/manpower/f2.png" />
          </div>
          <div class="">
            <img src="@/assets/images/manpower/f3.png" />
          </div>
          <div class="">
            <img src="@/assets/images/manpower/f4.png" />
          </div>
          <div class="">
            <img src="@/assets/images/manpower/f5.png" />
          </div>
          <div class="">
            <img src="@/assets/images/manpower/f6.png" />
          </div>
        </div>
      </div>
    </div>


   
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import { getWelfare } from "@/api/api";
export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {
      pageData: {},
      backurl: "https://jinlu-website.oss-cn-shenzhen.aliyuncs.com/",
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let this_ = this;
      let res = await getWelfare();
      console.log(res)
      this_.pageData = res.data;
    },
  }
};
</script>
<style scoped>
p{
  padding: 0;
  margin: 0;
}
.main-content {
  background: #ffffff;
}
.bg {
  background: #ffffff;
  padding: 80px;
}
.demo {
  /* padding: 85px 0; */
  padding: 0 200px;
}
.box {
  padding: 68px 0;
  padding-left: 136px;
  border-left: 2px solid #0864c6;
}

.demo .title-box {


}
.demo .title {
  
}
.demo .y-title {
  font-size: 53px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-, SourceHanSansCN-Bold-GBpc-EUC-H;
  font-weight: normal;
  color: #0864C6;
  letter-spacing:  4px;
}
.demo .z-title {
  display: flex;
  align-items: center;
  margin: 9px 0 ;

}
.demo .z-title .sp1 {
  width: 60px;
  height: 4px;
  background: #0864c6;
  border-radius: 0px 2px 2px 0px;
}
.demo .z-title .sp2 {
  width: 4px;
  height: 4px;
  background: #0864c6;
  margin: 0px 5px;
  border-radius: 2px 2px 2px 2px;
}
.demo .z-title .sp3 {
  margin-left: 40px;
  height: 40px;
  font-size: 30px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #0864c6;
  line-height: 45px;
}
.box2{
  padding-left: 136px;
  border-left: 2px solid #0864c6;
  padding-bottom: 100px;
}
.box3{
  border-left: 2px solid #0864c6;
  padding-bottom: 50px;
}
.title-box{
  line-height: 24px;
  font-size: 13px;
  color: #4D4D4D;
}
.row2{
  margin-top: 67px;
}
.title1{
  color: #0864C6;
  font-size: 30px;
  line-height: 45px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-;
}
.title2{
  font-size: 65px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H-;
  font-weight: normal;
  color: #0864C6;
  line-height: 119px;
}
.title3{
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  line-height: 37px;
}
.row3 .con{
  display: flex;
  flex-wrap: wrap;
  padding-right: 330px;
}
.row3 .con div{
  width: 217px;
  height: 54px;
  margin-right: 10px;
}
.row3 .con div img{
  display: inline-block;
  width: 217px;
  height: 54px;
}
.row3 .con div:nth-child(odd){
  margin-bottom: 10px;
}
.row3 .con div:nth-child(even){

  margin-bottom: 10px;
}

.bg-pos {
  position: relative;
}
.pos {
  width: 1203px;
  padding: 45px 45px 45px 136px;
  background: #0864C6;
  border-radius: 0px 0px 0px 0px;
  font-size: 25px;
  font-family: SourceHanSansSC-Normal-GBpc-EUC-H-,
    SourceHanSansSC-Normal-GBpc-EUC-H;
  font-weight: normal;
  color: #efefef;
  line-height: 45px;
  display: flex;

  align-items: center;
}
.con1{
  font-size: 20px;
  font-family: SourceHanSansCN-Light-GBpc-EUC-H-, SourceHanSansCN-Light-GBpc-EUC-H;
  font-weight: normal;
  color: #4D4D4D;
  line-height: 51px;
}
.con div{
  display: inline-block;
}
.con div img{
  display: inline-block;
  width: 325px;
  height: 80px;
  background: #0864C6;
}
.con div{
  margin-right: 20px;
}
.con div:nth-child(odd){
  margin-bottom: 20px;
}
.con div:nth-child(even){

  margin-bottom: 10px;
}
.con img:hover{
  box-shadow: 0 0 60px rgba(0,0,0,.5);
}
.demo {
  text-align: justify;
}
</style>